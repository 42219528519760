// cSpell:ignore francais

import overallPhoto from '../../../assets/order/retouches/overall.jpg';
import pantsPhoto from '../../../assets/order/retouches/pants.jpg';
import coatPhoto from '../../../assets/order/retouches/coat.jpg';
import dressPhoto from '../../../assets/order/retouches/dress.jpg';
import shirtPhoto from '../../../assets/order/retouches/shirt.jpg';
import skirtPhoto from '../../../assets/order/retouches/skirt.jpg';
import pulloverPhoto from '../../../assets/order/retouches/pullover.jpg';
import shortsPhoto from '../../../assets/order/retouches/shorts.jpg';
import weddingPhoto from '../../../assets/order/retouches/wedding.jpg';
import curtainPhoto from '../../../assets/order/retouches/curtain.jpg';
import cushionPhoto from '../../../assets/order/retouches/cushion.jpg';
import linensPhoto from '../../../assets/order/retouches/linens.jpg';
import lingeriePhoto from '../../../assets/order/retouches/lingerie.jpg';
import accessoriesPhoto from '../../../assets/order/retouches/accessories.jpg';
import suitPhoto from '../../../assets/order/retouches/suit.jpg';

import bagPhoto from '../../../assets/order/creations/bag.jpg';
import toteBagPhoto from '../../../assets/order/creations/toteBag.jpg';
import clutchBagPhoto from '../../../assets/order/creations/clutchBag.jpg';
import cushionCreationPhoto from '../../../assets/order/creations/cushion.jpg';
import sofaCreationPhoto from '../../../assets/order/creations/sofa.jpg';
import plaidCreationPhoto from '../../../assets/order/creations/plaid.jpg';
import chairCreationPhoto from '../../../assets/order/creations/chair.jpg';
import curtainsCreationPhoto from '../../../assets/order/creations/curtains.jpg';

export const clothPieces = [
  { name: 'Pantalon', slug: 'pant', image: pantsPhoto },
  { name: 'Manteau & Veste', slug: 'mant', image: coatPhoto },
  { name: 'Robe', slug: 'robe', image: dressPhoto },
  { name: 'Haut', slug: 'haut', image: shirtPhoto },
  { name: 'Costume', slug: 'cost', image: suitPhoto },
  { name: 'Robe de mariée', slug: 'mari', image: weddingPhoto },
  { name: 'Pull & gilet', slug: 'pull', image: pulloverPhoto },
  { name: 'Jupe', slug: 'jupe', image: skirtPhoto },
  { name: 'Combinaison', slug: 'comb', image: overallPhoto },
  { name: 'Maillot de bain', slug: 'mail', image: shortsPhoto },
  { name: 'Lingerie', slug: 'ling', image: lingeriePhoto },
  { name: 'Accessoires', slug: 'acce', image: accessoriesPhoto },
];

export const decoPieces = [
  { name: 'Rideau', slug: 'ride', image: curtainPhoto },
  { name: 'Housse de coussin', slug: 'cous', image: cushionPhoto },
  { name: 'Linge de maison', slug: 'mais', image: linensPhoto },
];

export const clothesCreations = [
  { name: 'Sac', slug: 'crea_sac', image: bagPhoto },
  { name: 'Tote bag', slug: 'crea_tote', image: toteBagPhoto },
  { name: 'Pochette', slug: 'crea_poch', image: clutchBagPhoto },
];

export const decoCreations = [
  { name: 'Housse de canapé', slug: 'crea_sofa', image: sofaCreationPhoto },
  { name: 'Plaid/Couvre-lit', slug: 'crea_plai', image: plaidCreationPhoto },
  { name: 'Housse de coussin', slug: 'crea_hous', image: cushionCreationPhoto },
  { name: 'Housse de chaise', slug: 'crea_chai', image: chairCreationPhoto },
  { name: 'Rideaux', slug: 'crea_ride', image: curtainsCreationPhoto },
];
