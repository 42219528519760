import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import iconInformationNavy from '../../assets/order/iconInformationNavy.svg';
import { colors, mobileThresholdPixels } from '../styledComponents';
import {
  StepContainer,
  TreeRow,
  SmallContent,
  Question,
  Label,
  Row2,
  AnswerCard,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    width: calc(100vw - 40px);
  }
`;

const Tooltip = styled(ReactTooltip)`
  margin: 15px;
  width: 245px;
  border-radius: 4px;
  color: ${colors.white};
  opacity: 1 !important;
  background-color: ${colors.navy} !important;
  text-align: left;
  font-size: 13px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 203px;
    font-size: 12px;
  }
`;

const Step1Lining = ({ selectLining, selectedLining, isLast }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive={Object.keys(selectedLining).length > 0}
        height={isLast ? 20 : 146}
        noLine={isLast}
      />
      <Tooltip place="right" type="none" data-event="click" />
      <SmallContent flexStart marginLeft>
        <Question>
          {'Y a-t-il une doublure ? '}
          <sup
            data-tip="La doublure est le tissu situé en plus à l’intérieur
            du vêtement souvent plus fin que la matière visible du vêtement."
          >
            <img src={iconInformationNavy} alt="Information" />
          </sup>
        </Question>
        <Row2>
          <CardsContainer>
            <AnswerCard
              mobileLonger
              onClick={() => selectLining('haveLining')}
              isActive={selectedLining === 'haveLining'}
            >
              <Label>Oui</Label>
            </AnswerCard>
            <AnswerCard
              mobileLonger
              onClick={() => selectLining('noLining')}
              isActive={selectedLining === 'noLining'}
            >
              <Label>Non</Label>
            </AnswerCard>
            <AnswerCard
              longer
              onClick={() => selectLining('dontKnowLining')}
              isActive={selectedLining === 'dontKnowLining'}
            >
              <Label>Je ne sais pas</Label>
            </AnswerCard>
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

Step1Lining.propTypes = {
  selectLining: PropTypes.func,
  selectedLining: PropTypes.string,
  isLast: PropTypes.bool,
};

Step1Lining.defaultProps = {
  selectLining() {},
  selectedLining: '',
  isLast: true,
};

export default Step1Lining;
