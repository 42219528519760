import React from 'react';
import PropTypes from 'prop-types';

import SuitPiecesSelector from './SuitPiecesSelector';
import SuitProblemsSelector from './SuitProblemsSelector';
import SuitItemsSelector from './SuitItemsSelector';
import SuitItemQuantitySelector from './Step1-5Multiple';
import SuitLiningSelector from './Step1-6Lining';
import treeAlterationSuit from './treeAlterationSuit';
import EmbroideriesSelector from './Step1-2Embroideries';
import UpcyclingSelector from './UpcyclingSelector';
import treeUpcycling from './treeUpcycling';

const shouldDisplayLining = (suitPiece, problems, items) => {
  if (problems.length === 0) return false;
  const problemsToCheckForLining = problems
    .filter((problem) => problem.includes(`${suitPiece}_`))
    .map((problem) => problem.replace(`${suitPiece}_`, ''))
    .filter((problem) => !!treeAlterationSuit.pieces[suitPiece].problems[problem].answers);

  if (problemsToCheckForLining.length === 0) return false;

  for (let i = 0; i < problemsToCheckForLining.length; i += 1) {
    const problemToCheck = problemsToCheckForLining[i];
    const problemItems = items.filter(({ piece, problem }) => piece === suitPiece && problem === problemToCheck);
    if (problemItems.length === 0) return false;
  }

  return true;
};

const renderSuitLiningSelector = (suitPieces, suitPiece, suitPieceIndex, problems, items, suitPieceLinings, selectSuitPieceLining) => {
  if (!shouldDisplayLining(suitPiece, problems, items)) return null;
  return (
    <SuitLiningSelector
      selectedLining={suitPieceLinings[suitPiece]}
      selectLining={(haveLining) => selectSuitPieceLining(suitPiece, haveLining)}
      isLast={suitPieceIndex === suitPieces.length - 1}
    />
  );
};

const renderSuitItemsSelector = (piece, problem, items, toggleSuitItem) => (
  <SuitItemsSelector
    key={problem}
    suitPiece={piece}
    suitProblem={problem}
    suitItems={items}
    toggleSuitItem={toggleSuitItem}
  />
);

const renderSuitProblemsSelector = ({
  suitPieces, toggleSuitProblem, suitItems, toggleSuitItem, createRange, setSuitItemQuantity,
  suitProblems, suitPieceLinings, selectSuitPieceLining,
}) => suitPieces.map((suitPiece, suitPieceIndex) => {
  const suitProblemsForCurrentPiece = suitProblems
    .filter((suitProblem) => suitProblem.includes(`${suitPiece}_`)
      && treeAlterationSuit.pieces[suitPiece].problems[suitProblem.replace(`${suitPiece}_`, '')].answers?.length > 1);
  return (
    <React.Fragment key={suitPiece}>
      <SuitProblemsSelector
        suitPiece={suitPiece}
        suitProblems={suitProblems}
        toggleSuitProblem={toggleSuitProblem}
        isLast={suitPieceIndex === suitPieces.length - 1}
      />
      {suitProblemsForCurrentPiece
        .map((suitProblem) => {
          const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
          const suitItemsForCurrentProblem = suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblemSlug);
          return (
            <React.Fragment key={suitProblem}>
              {renderSuitItemsSelector(suitPiece, suitProblemSlug, suitItemsForCurrentProblem, toggleSuitItem)}
              {suitItemsForCurrentProblem.filter(({ isMultiple }) => isMultiple)
                .map((suitItem) => (
                  <SuitItemQuantitySelector
                    key={suitItem.id}
                    createRange={createRange}
                    slug={suitItem.slug}
                    itemId={suitItem.id}
                    selectedValue={suitItem.quantity}
                    setSelectValue={setSuitItemQuantity}
                  />
                ))}
            </React.Fragment>
          );
        })}
      {renderSuitLiningSelector(suitPieces, suitPiece, suitPieceIndex, suitProblems, suitItems, suitPieceLinings, selectSuitPieceLining)}
    </React.Fragment>
  );
});

const renderSuitEmbroideriesSelector = ({
  suitPieces, displaySummary, suitPiecesEmbroideries, setEmbroiderySingleChoiceForSuit, setEmbroideryMultipleChoicesForSuit,
}) => (suitPieces.map((suitPiece, suitPieceIndex) => (
  <EmbroideriesSelector
    key={suitPiece}
    setSingleChoice={(index, stateName) => setEmbroiderySingleChoiceForSuit(index, stateName, suitPiece)}
    setMultipleChoices={(index, stateName) => setEmbroideryMultipleChoicesForSuit(index, stateName, suitPiece)}
    embroideries={suitPiecesEmbroideries[suitPiece].embroideries}
    activeEmbroideryFontSize={suitPiecesEmbroideries[suitPiece].activeEmbroideryFontSize}
    activeEmbroideryDrawingSize={suitPiecesEmbroideries[suitPiece].activeEmbroideryDrawingSize}
    isDrawingFull={suitPiecesEmbroideries[suitPiece].isDrawingFull}
    isDrawingEasy={suitPiecesEmbroideries[suitPiece].isDrawingEasy}
    pieceSlug={suitPiece}
    displaySummary={suitPieceIndex === suitPieces.length - 1 && displaySummary}
    suitLabel={`pour ${treeAlterationSuit.pieces[suitPiece].label.toLowerCase()} `}
    isLast={suitPieceIndex === suitPieces.length - 1}
  />
)));

const renderSuitUpcyclingSelector = ({
  suitPieces, suitPiecesUpcycling, toggleSuitUpcyclingCategory, toggleSuitUpcyclingItem, setSuitUpcyclingItemQuantity, createRange,
}) => (suitPieces.map((suitPiece, index) => (
  <UpcyclingSelector
    key={suitPiece}
    tree={treeUpcycling[suitPiece]}
    upcyclingCategories={suitPiecesUpcycling[suitPiece].upcyclingCategories}
    toggleUpcyclingCategory={(slug) => toggleSuitUpcyclingCategory(suitPiece, slug)}
    upcyclingItems={suitPiecesUpcycling[suitPiece].upcyclingItems}
    toggleUpcyclingItem={(item) => toggleSuitUpcyclingItem(suitPiece, item)}
    createRange={createRange}
    setUpcyclingItemQuantity={(id, value) => setSuitUpcyclingItemQuantity(suitPiece, id, value)}
    isJacket={suitPiece === 'mant'}
    isLast={index === suitPieces.length - 1}
  />
)));

const SuitSelector = (props) => {
  const { suitPieces, toggleSuitPiece, typeOfWork } = props;
  return (
    <>
      <SuitPiecesSelector
        suitPieces={suitPieces}
        toggleSuitPiece={toggleSuitPiece}
        typeOfWork={typeOfWork}
      />
      {typeOfWork === 'alteration' && renderSuitProblemsSelector(props)}
      {typeOfWork === 'embroidery' && renderSuitEmbroideriesSelector(props)}
      {typeOfWork === 'transformation' && renderSuitUpcyclingSelector(props)}
    </>
  );
};

SuitSelector.propTypes = {
  suitPieces: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSuitPiece: PropTypes.func.isRequired,
  suitPieceLinings: PropTypes.shape({}).isRequired,
  typeOfWork: PropTypes.string.isRequired,
  suitPiecesEmbroideries: PropTypes.shape({}).isRequired,
  suitPiecesUpcycling: PropTypes.shape({}).isRequired,
};

export default SuitSelector;
