import React from 'react';
import PropTypes from 'prop-types';

import {
  TreeRow,
  StepContainer,
  SmallContent,
  Question,
  Label,
  Row2,
  AnswerCard,
  CardsContainer,
  customStyles,
  setTheme,
  Select,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';

const Step1Linens = ({
  createRange, setSelect, setSingleChoice, setMultipleChoices,
  problems, alterations, sizes, where,
}) => {
  const isProblemSelected = problems.reduce((acc, problem) => acc || problem.isActive, false);
  const isAlterationSelected = alterations.reduce((acc, alteration) => acc || alteration.isActive, false);
  const isSizeSelected = sizes.reduce((acc, size) => acc || size.isActive, false);
  const isWhereSelected = where.reduce((acc, location) => acc || location.isActive, false);
  return (
    <div>
      <TreeRow>
        <StepContainer>
          <ProgressBarVertical
            isActive={isProblemSelected}
            height={141}
          />
          <SmallContent marginBottom="30px" mobileNoMarginTop marginTop={0.01}>
            <Question center>Indiquez-nous le problème :</Question>
            <Row2 noMarginBottomMobile>
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                {problems.map((problem, index) => (
                  <AnswerCard
                    key={problem.label}
                    isActive={problem.isActive}
                    onClick={() => setMultipleChoices(index, 'linensProblems')}
                  >
                    <Label>{problem.label}</Label>
                  </AnswerCard>
                ))}
              </CardsContainer>
            </Row2>
          </SmallContent>
        </StepContainer>
      </TreeRow>

      {isProblemSelected && problems[0].isActive
        && (
          <TreeRow>
            <StepContainer>
              <ProgressBarVertical
                isActive={isAlterationSelected}
                height={141}
              />
              <SmallContent marginBottom="30px" mobileNoMarginTop marginTop={0.01}>
                <Question center>Que souhaitez-vous réparer ?</Question>
                <Row2 noMarginBottomMobile>
                  <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                    {alterations.map((alteration, index) => (
                      <AnswerCard
                        key={alteration.label}
                        isActive={alteration.isActive}
                        onClick={() => setMultipleChoices(index, 'linensAlterations')}
                      >
                        <Label>{alteration.label}</Label>
                      </AnswerCard>
                    ))}
                  </CardsContainer>
                </Row2>
              </SmallContent>
            </StepContainer>
          </TreeRow>
        )}

      {isAlterationSelected
        && alterations.filter((alteration) => alteration.isActive).map(({ question: { label, answer }, id }, index) => (
          <TreeRow key={id}>
            <StepContainer>
              <ProgressBarVertical
                isActive
                height={141}
                noLine={
                  alterations.filter((alteration) => alteration.isActive).length - 1 === index
                  && !problems[1].isActive
                }
              />
              <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
                <Question center>{label}</Question>
                <Row2 noMarginBottomMobile>
                  <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                    <Select
                      placeholder=""
                      value={{ label: answer, value: answer }}
                      onChange={(selectedValue) => setSelect(selectedValue.value, 'linensAlterations', true, index)}
                      options={createRange(1, 20)}
                      styles={customStyles}
                      theme={(theme) => setTheme(theme)}
                    />
                  </CardsContainer>
                </Row2>
              </SmallContent>
            </StepContainer>
          </TreeRow>
        ))}

      {isProblemSelected && problems[1].isActive
        && (
          <TreeRow>
            <StepContainer>
              <ProgressBarVertical
                isActive={isSizeSelected}
                height={141}
              />
              <SmallContent marginBottom="30px" mobileNoMarginTop marginTop={0.01}>
                <Question center>Quelle est la taille de votre linge ?</Question>
                <Row2 noMarginBottomMobile>
                  <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                    {sizes.map((size, index) => (
                      <AnswerCard
                        key={size.label}
                        isActive={size.isActive}
                        onClick={() => setSingleChoice(index, 'linensSizes', true)}
                      >
                        <Label>{size.label}</Label>
                      </AnswerCard>
                    ))}
                  </CardsContainer>
                </Row2>
              </SmallContent>
            </StepContainer>
          </TreeRow>
        )}

      {isSizeSelected
        && (
          <TreeRow>
            <StepContainer>
              <ProgressBarVertical
                isActive={isWhereSelected}
                height={141}
                noLine
              />
              <SmallContent marginBottom="30px" mobileNoMarginTop marginTop={0.01}>
                <Question center>Il est trop grand sur :</Question>
                <Row2 noMarginBottomMobile>
                  <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                    {where.map((location, index) => (
                      <AnswerCard
                        key={location.label}
                        isActive={location.isActive}
                        onClick={() => setSingleChoice(index, 'linensWhere', true)}
                      >
                        <Label>{location.label}</Label>
                      </AnswerCard>
                    ))}
                  </CardsContainer>
                </Row2>
              </SmallContent>
            </StepContainer>
          </TreeRow>
        )}

    </div>
  );
};

Step1Linens.propTypes = {
  createRange: PropTypes.func.isRequired,
  setSelect: PropTypes.func.isRequired,
  setSingleChoice: PropTypes.func.isRequired,
  setMultipleChoices: PropTypes.func.isRequired,
  problems: PropTypes.arrayOf(PropTypes.shape({
    isActive: PropTypes.bool,
  })).isRequired,
  alterations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  where: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Step1Linens;
