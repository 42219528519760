import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toFormattedCreationSizeAnswer } from '../../services/formatting';

import {
  TreeRow,
  StepContainer,
  SmallContent,
  Question,
  Label as LabelStyle,
  Row2,
  AnswerCard,
  colors,
  customStyles,
  setTheme,
  Select,
  mobileThresholdPixels,
  CardsContainer as CardsContainerOrder,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import { treeCreation } from './tree';

const TipContainer = styled.div`
  position: absolute;
`;

const TipText = styled.p`
  position: relative;
  top: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${colors.navy};
  margin-left: 15px;
`;

const CardsContainer = styled(CardsContainerOrder)`
  margin-top: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  border-radius: 3px 3px 0px 0px;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
`;

const AnswerCardImg = styled.div`
  cursor: pointer;
  margin: 0px 15px 10px 15px;
  width: 242px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 2.5px 0px 2.5px 0px;
    width: 100%;
  }
`;

const LabelImg = styled.button`
  background-color: ${(props) => (props.isActive ? colors.navy : colors.white)};
  border: 0.75px solid;
  border-color: ${(props) => (props.isActive ? colors.navy : colors.blueGrey)};
  border-radius: 0px 0px 3px 3px;
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? colors.white : colors.navy)};
  cursor: pointer;
  height: ${({ seeInRDV }) => seeInRDV ? '100%' : '42px'};
  justify-content: center;
  outline: 0;
  transition: all 0.5s ease;
  width: 100%;
`;

const Label = styled(LabelStyle)`
  text-align: ${({ seeInRDV }) => seeInRDV ? 'center' : 'left'};
  margin-left: ${({ seeInRDV }) => seeInRDV ? '0px' : '18px'};
`;

export const renderQuestionAndAnswers = (
  question,
  answers = [],
  selectedAnswer = {},
  selectAnswer,
  stateNameForClick,
  noLine = false,
) => {
  const show = answers[0]?.image !== undefined;

  return (
    <TreeRow key={stateNameForClick}>
      <StepContainer>
        <ProgressBarVertical
          isActive={Object.keys(selectedAnswer).length > 0}
          noLine={noLine}
        />
        <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
          <Question center>{question}</Question>
          <Row2 noMarginBottomMobile>
            {show ? (
              <CardsContainer>
                {answers.map((answer) => (
                  <AnswerCardImg
                    key={answer.slug}
                    onClick={() => selectAnswer(stateNameForClick, answer)}
                  >
                    {answer.image && (
                      <ImgContainer littleCard>
                        <Img src={answer.image} />
                      </ImgContainer>
                    )}
                    <LabelImg isActive={selectedAnswer.slug === answer.slug} seeInRDV={answer.slug === 'seeInRDV'}>
                      <Label seeInRDV={answer.slug === 'seeInRDV'}>{toFormattedCreationSizeAnswer(answer.label)}</Label>
                    </LabelImg>
                  </AnswerCardImg>
                ))}
              </CardsContainer>
            ) : (
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                {answers.map((answer) => (
                  <AnswerCard
                    key={answer.slug}
                    isActive={selectedAnswer.slug === answer.slug}
                    onClick={() => selectAnswer(stateNameForClick, answer)}
                    extraMarginBottom={answer.label.includes('*')}
                  >
                    <Label>{toFormattedCreationSizeAnswer(answer.label)}</Label>
                    {answer.label.includes('*') && (
                      <TipContainer>
                        <TipText>*À vous de nous fournir l’anse de votre choix :)</TipText>
                      </TipContainer>
                    )}
                  </AnswerCard>
                ))}
              </CardsContainer>
            )}
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>
  );
};

export const renderSelect = (
  question,
  createRange,
  numberOfPieces = 0,
  selectAnswer,
  stateNameForClick,
  noLine = false,
  subLabel = '',
) => (
  <TreeRow key={stateNameForClick}>
    <StepContainer>
      <ProgressBarVertical isActive={numberOfPieces > 0} noLine={noLine} />
      <SmallContent marginBottom="30px" mobileNoMarginTop>
        <Question center>{question.replace('[replace]', subLabel)}</Question>
        <Row2 noMarginBottomMobile>
          <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
            <Select
              placeholder=""
              value={{ label: numberOfPieces, value: numberOfPieces }}
              onChange={(selectedValue) => selectAnswer(stateNameForClick, selectedValue.value)}
              options={createRange(1, 10)}
              styles={customStyles}
              theme={(theme) => setTheme(theme)}
            />
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

const Step1Creations = ({
  creationSlug, creationShape, creationSize, creationFirstOption, creationSecondOption,
  selectedCreationNumberOfPieces, createRange, selectCreationOptions, creationHeight,
}) => {
  const slugParams = {
    range: {
      render: 'select', state: selectedCreationNumberOfPieces, stateName: 'selectedCreationNumberOfPieces',
    },
    shape: {
      render: 'questionsAndAnswers', state: creationShape, stateName: 'creationShape',
    },
    size: {
      render: 'questionsAndAnswers', state: creationSize, stateName: 'creationSize',
    },
    handle: {
      render: 'questionsAndAnswers', state: creationFirstOption, stateName: 'creationFirstOption',
    },
    height: {
      render: 'questionsAndAnswers', state: creationHeight, stateName: 'creationHeight',
    },
    head: {
      render: 'questionsAndAnswers', state: creationFirstOption, stateName: 'creationFirstOption',
    },
    lining: {
      render: 'questionsAndAnswers', state: creationFirstOption, stateName: 'creationFirstOption',
    },
    liningLastQuestion: {
      render: 'questionsAndAnswers', state: creationSecondOption, stateName: 'creationSecondOption',
    },
    closing: {
      render: 'questionsAndAnswers', state: creationSecondOption, stateName: 'creationSecondOption',
    },
    duffel: {
      render: 'questionsAndAnswers', state: creationSecondOption, stateName: 'creationSecondOption',
    },
  };
  const { questions } = treeCreation.find((cloth) => cloth.slug === creationSlug);
  return (
    <div>
      {questions.map(({
        slug, label, answers, subLabels,
      }, index) => {
        const { render, state, stateName } = slugParams[slug];
        const previousState = index > 0 ? slugParams[questions[index - 1].slug].state : { notEmpty: true };
        const isLast = index === questions.length - 1;
        return (
          render === 'select' ? (
            (typeof previousState !== 'object' || !!Object.keys({ ...previousState }).length)
            && renderSelect(label, createRange, state, selectCreationOptions, stateName, isLast, subLabels?.[creationShape.slug])
          ) : (
            (typeof previousState !== 'object' || !!Object.keys({ ...previousState }).length)
            && renderQuestionAndAnswers(label, answers, state, selectCreationOptions, stateName, isLast)
          )
        );
      })}
    </div>
  );
};

Step1Creations.propTypes = {
  creationSlug: PropTypes.string.isRequired,
  creationShape: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  creationSize: PropTypes.shape({}).isRequired,
  creationHeight: PropTypes.shape({}).isRequired,
  creationFirstOption: PropTypes.shape({}).isRequired,
  creationSecondOption: PropTypes.shape({}).isRequired,
  selectedCreationNumberOfPieces: PropTypes.number.isRequired,
  selectCreationOptions: PropTypes.func.isRequired,
  createRange: PropTypes.func.isRequired,
};

export default Step1Creations;
