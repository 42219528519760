import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderSelect, renderQuestionAndAnswers } from './Step1-5Creations';
import { treeChairCreation } from './treeChairCreation';
import ProgressBarVertical from './ProgressBarVertical';
import { toFormattedCreationSizeAnswer } from '../../services/formatting';
import {
  TreeRow, StepContainer, SmallContent, Question, Label, colors, Row2, mobileThresholdPixels, CardsContainer as CardsContainerOrder,
} from './orderStyledComponents';

const CardsContainer = styled(CardsContainerOrder)`
  margin-top: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const AnswerCardImg = styled.div`
  cursor: pointer;
  margin: 0px 15px 10px 15px;
  width: 242px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 2.5px 0px 2.5px 0px;
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: '170px';
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  border-radius: 3px 3px 0px 0px;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
`;

const LabelImg = styled.button`
  background-color: ${(props) => (props.isActive ? colors.navy : colors.white)};
  border: 0.75px solid;
  border-color: ${(props) => (props.isActive ? colors.navy : colors.blueGrey)};
  border-radius: 0px 0px 3px 3px;
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? colors.white : colors.navy)};
  cursor: pointer;
  height: ${({ seeInRDV }) => seeInRDV ? '100%' : '42px'};
  justify-content: center;
  outline: 0;
  transition: all 0.5s ease;
  width: 100%;
`;

const changeSlugAndSize = (selectAnswer, answer, setNextQuestions) => {
  const { questions, ...saveToState } = answer;
  setNextQuestions(questions);
  selectAnswer('setChairSelection', {
    creationSlug: answer.clothSlug,
    creationSize: answer.clothSlug === 'crea_chai' ? saveToState : {},
    creationFirstOption: {},
    creationSecondOption: {},
  });
};

const renderCreationSlugQuestion = (creationSlug, creationSize, selectAnswer, setNextQuestions) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical isActive={Object.keys(creationSize).length > 0 || creationSlug !== 'crea_chai'} />
      <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
        <Question center>{treeChairCreation.question}</Question>
        <Row2 noMarginBottomMobile>
          <CardsContainer>
            {treeChairCreation.answers.map((answer) => (
              <AnswerCardImg
                key={answer.id}
                onClick={() => changeSlugAndSize(selectAnswer, answer, setNextQuestions)}
              >
                {answer.image && (
                  <ImgContainer>
                    <Img src={answer.image} />
                  </ImgContainer>
                )}
                <LabelImg isActive={creationSize.id === answer.id || (creationSlug === answer.clothSlug && creationSlug === 'crea_stoo')}>
                  <Label>{toFormattedCreationSizeAnswer(answer.label)}</Label>
                </LabelImg>
              </AnswerCardImg>
            ))}
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

const ChairCreationsSelector = ({
  creationSize, creationFirstOption, creationSecondOption, creationSlug,
  selectedCreationNumberOfPieces, createRange, selectCreationOptions,
}) => {
  const [nextQuestions, setNextQuestions] = useState([]);
  const slugParams = {
    range: {
      render: 'select', state: selectedCreationNumberOfPieces, stateName: 'selectedCreationNumberOfPieces',
    },
    size: {
      render: 'questionsAndAnswers', state: creationSize, stateName: 'creationSize',
    },
    lining: {
      render: 'questionsAndAnswers', state: creationFirstOption, stateName: 'creationFirstOption',
    },
    closing: {
      render: 'questionsAndAnswers', state: creationSecondOption, stateName: 'creationSecondOption',
    },
  };
  return (
    <div>
      {renderCreationSlugQuestion(creationSlug, creationSize, selectCreationOptions, setNextQuestions)}
      {nextQuestions.map(({ slug, label, answers }, index) => {
        const { render, state, stateName } = slugParams[slug];
        const previousState = index > 0 ? slugParams[nextQuestions[index - 1].slug].state : { notEmpty: true };
        const isLast = index === nextQuestions.length - 1;
        return (
          render === 'select' ? (
            (typeof previousState !== 'object' || !!Object.keys({ ...previousState }).length)
            && renderSelect(label, createRange, state, selectCreationOptions, stateName, isLast)
          ) : (
            (typeof previousState !== 'object' || !!Object.keys({ ...previousState }).length)
            && renderQuestionAndAnswers(label, answers, state, selectCreationOptions, stateName, isLast)
          )
        );
      })}
    </div>
  );
};

ChairCreationsSelector.propTypes = {
  creationSlug: PropTypes.string.isRequired,
  creationSize: PropTypes.shape({}).isRequired,
  creationFirstOption: PropTypes.shape({}).isRequired,
  creationSecondOption: PropTypes.shape({}).isRequired,
  selectedCreationNumberOfPieces: PropTypes.number.isRequired,
  createRange: PropTypes.func.isRequired,
  selectCreationOptions: PropTypes.func.isRequired,
};

export default ChairCreationsSelector;
