import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  TreeRow, StepContainer, SmallContent, Question, Label, customStyles, setTheme,
  Row2, AnswerCard, mobileThresholdPixels, Select, CardsContainer as CardsContainerOrder,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import { treeSofaCreations } from './tree';
import SofaPartAnswersSelector from './SofaPartAnswersSelector';

const CardsContainer = styled(CardsContainerOrder)`
  margin-top: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const renderSofaPartsSelector = (
  sofaParts,
  toggleSofaPart,
) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical isActive={sofaParts.length > 0} />
      <SmallContent marginBottom="30px" mobileNoMarginTop>
        <Question center>{treeSofaCreations.question}</Question>
        <Row2 noMarginBottomMobile>
          <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
            {Object.entries(treeSofaCreations.parts).map(([key, part]) => (
              <AnswerCard
                key={key}
                isActive={sofaParts.includes(key)}
                onClick={() => toggleSofaPart(key)}
              >
                <Label>{part.label}</Label>
              </AnswerCard>
            ))}
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

const renderSelect = (
  label,
  createRange,
  value,
  selectAnswer,
  sofaPart,
) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical isActive={value > 0} height={141} />
      <SmallContent marginBottom="30px" mobileNoMarginTop>
        <Question center>{label}</Question>
        <Row2 noMarginBottomMobile>
          <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
            <Select
              placeholder=""
              value={{ label: value, value }}
              onChange={(selectedValue) => selectAnswer(sofaPart, selectedValue.value)}
              options={createRange(1, 20)}
              styles={customStyles}
              theme={(theme) => setTheme(theme)}
            />
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

const SofaCreationsSelector = ({
  sofaParts, toggleSofaPart, sofaPartsSubTypes, toggleSofaPartSubType, sofaPartsItems, toggleSofaPartItem, createRange,
  sofaPartsNumber, setSofaPartNumber,
}) => (
  <div>
    {renderSofaPartsSelector(sofaParts, toggleSofaPart, sofaPartsSubTypes, toggleSofaPartSubType)}
    {sofaParts.map((sofaPart, sofaPartIndex) => {
      const treeSofaPart = treeSofaCreations.parts[sofaPart];
      return (
        <React.Fragment key={sofaPart}>
          {['crea_assi', 'crea_doss'].includes(sofaPart)
            && renderSelect(`Combien y a-t-il d${sofaPart === 'crea_assi' ? '’assises' : 'e dossiers'} ?`, createRange, sofaPartsNumber[sofaPart], setSofaPartNumber, sofaPart)}
          <SofaPartAnswersSelector
            part={sofaPart}
            questions={treeSofaCreations.parts[sofaPart].subTypeQuestions}
            answers={sofaPartsSubTypes}
            toggleAnswer={toggleSofaPartSubType}
            isLast={!treeSofaPart.toppingQuestions && sofaPartIndex === sofaParts.length - 1}
          />
          {treeSofaPart.toppingQuestions && Object.keys(sofaPartsSubTypes[sofaPart]).length === treeSofaPart.subTypeQuestions.length && (
            <SofaPartAnswersSelector
              part={sofaPart}
              questions={treeSofaCreations.parts[sofaPart].toppingQuestions}
              answers={sofaPartsItems}
              toggleAnswer={toggleSofaPartItem}
              isLast={sofaPartIndex === sofaParts.length - 1}
            />
          )}
        </React.Fragment>
      );
    })}
  </div>
);

SofaCreationsSelector.propTypes = {
  sofaParts: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSofaPart: PropTypes.func.isRequired,
  sofaPartsSubTypes: PropTypes.shape({}).isRequired,
  toggleSofaPartSubType: PropTypes.func.isRequired,
  toggleSofaPartItem: PropTypes.func.isRequired,
  sofaPartsItems: PropTypes.shape({}).isRequired,
  sofaPartsNumber: PropTypes.shape({}).isRequired,
  setSofaPartNumber: PropTypes.func.isRequired,
  createRange: PropTypes.func.isRequired,
};

export default SofaCreationsSelector;
