import React from 'react';
import PropTypes from 'prop-types';

const ComponentOrEmptyDiv = ({ mustShow, children }) => {
  if (!mustShow) return <div />;
  return children;
};

ComponentOrEmptyDiv.propTypes = {
  mustShow: PropTypes.bool,
  children: PropTypes.node,
};

ComponentOrEmptyDiv.defaultProps = {
  mustShow: false,
  children: <div />,
};

export default ComponentOrEmptyDiv;
