import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels, colors } from '../styledComponents';
import CardSelector from './CardSelector';
import fabrics from './fabrics';

export const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: regular;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 40px 37px 0px 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 290px;
    margin: 20px auto auto;
    line-height: 17px;
    font-size: 14px;
  }
`;

const mustHideFabric = (slug, cloth) => (
  (slug === 'leather' && cloth === 'acce')
  || (slug === 'suit' && cloth === 'haut')
  || (slug === 'fur' && ['haut', 'pant', 'robe', 'jupe'].includes(cloth))
  || (slug === 'bath' && ['robe', 'jupe'].includes(cloth))
);

const FabricCards = ({
  isMobile, selectFabric, selectedFabric, selectedCloth, isFurSelected,
}) => (
  fabrics
    .filter(({ slug }) => !mustHideFabric(slug, selectedCloth))
    .map(({
      slug, name, image, filterContent,
    }) => (
      <CardSelector
        key={slug}
        slug={slug}
        image={image}
        label={(isMobile && slug === 'other') ? 'Autre' : name}
        alt={`Tilli retouche vos vêtements en ${name}`}
        onClick={selectFabric}
        littleCard
        active={selectedFabric === slug}
        filterContent={filterContent}
        isFurSelected={isFurSelected}
      />
    ))
);

FabricCards.propTypes = {
  isMobile: PropTypes.bool,
  selectFabric: PropTypes.func,
  selectedFabric: PropTypes.string,
  selectedCloth: PropTypes.string,
  isFurSelected: PropTypes.bool,
};

FabricCards.defaultProps = {
  isMobile: false,
  selectedFabric: '',
  selectFabric() { },
  selectedCloth: '',
  isFurSelected: false,
};

export default FabricCards;
