import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import { treeAlterationSuit } from './tree';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const SuitPiecesSelector = ({ toggleSuitPiece, suitPieces, typeOfWork }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive={suitPieces.length > 0}
        height={146}
      />
      <SmallContent flexStart marginLeft>
        <Question>
          {treeAlterationSuit.question[typeOfWork]}
        </Question>
        <Row2>
          <CardsContainer>
            {Object.entries(treeAlterationSuit.pieces).map(([key, { label }]) => (
              <AnswerCard
                key={key}
                onClick={() => toggleSuitPiece(key)}
                isActive={suitPieces.includes(key)}
              >
                <Label>
                  {label}
                </Label>
              </AnswerCard>
            ))}
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

SuitPiecesSelector.propTypes = {
  toggleSuitPiece: PropTypes.func.isRequired,
  suitPieces: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeOfWork: PropTypes.string.isRequired,
};

export default SuitPiecesSelector;
