import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  colors, StepContainer, TreeRow, Label as StyledLabel, Row2, SmallContent, Question, AnswerCard as StyledAnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  border-radius: 3px 3px 0px 0px;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
`;

const AnswerCardImg = styled.div`
  cursor: pointer;
  margin: 0px 15px 10px 15px;
  width: 240px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 2.5px 0px 2.5px 0px;
    width: 100%;
  }
`;

const LabelImg = styled.button`
  background-color: ${(props) => (props.isActive ? colors.navy : colors.white)};
  border: 0.75px solid;
  border-color: ${(props) => (props.isActive ? colors.navy : colors.blueGrey)};
  border-radius: 0px 0px 3px 3px;
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? colors.white : colors.navy)};
  cursor: pointer;
  height: 42px;
  justify-content: center;
  outline: 0;
  transition: all 0.5s ease;
  width: 100%;
`;

const AnswerCard = styled(StyledAnswerCard)`
  ${({ mustAdaptToAnswerCardImages }) => mustAdaptToAnswerCardImages && `
    height: 212px;
    justify-content: center;
    align-items: center;
  `}
`;

const Label = styled(StyledLabel)`
  ${({ isCentered }) => isCentered && `
    text-align: center;
    margin-left: 0px;
  `}
`;

const SubLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
`;

const renderAnswerCardImage = (part, answers, currentQuestionSlug, answer, toggleAnswer) => (
  <AnswerCardImg
    key={answer.slug}
    onClick={() => toggleAnswer(part, currentQuestionSlug, answer.id || answer.slug)}
  >
    <ImgContainer littleCard>
      <Img src={answer.image} />
    </ImgContainer>
    <LabelImg isActive={answers[part]?.[currentQuestionSlug] === (answer.id || answer.slug)}>
      <Label>{answer.label}</Label>
    </LabelImg>
  </AnswerCardImg>
);

const SofaPartAnswersSelector = ({
  part, questions, answers, toggleAnswer, isLast,
}) => {
  const answersForCurrentPart = answers[part];
  const filteredQuestions = questions.filter((_, index) => index <= Object.keys(answersForCurrentPart).length);
  return (
    <>
      {filteredQuestions.map((currentQuestion, index) => {
        const rowHasImage = currentQuestion.answers.find(({ image }) => image !== undefined);
        return (
          <TreeRow key={currentQuestion.slug}>
            <StepContainer>
              <ProgressBarVertical
                isActive={!!answersForCurrentPart[currentQuestion.slug]}
                noLine={isLast && index === filteredQuestions.length - 1}
              />
              <SmallContent flexStart marginLeft>
                <Question>
                  {currentQuestion.question}
                </Question>
                <Row2 noMarginBottomMobile>
                  <CardsContainer>
                    {currentQuestion.answers.map((answer) => {
                      if (answer.image) return (renderAnswerCardImage(part, answers, currentQuestion.slug, answer, toggleAnswer));
                      return (
                        <AnswerCard
                          key={answer.slug}
                          onClick={() => toggleAnswer(part, currentQuestion.slug, answer.id || answer.slug)}
                          isActive={answers[part]?.[currentQuestion.slug] === (answer.id || answer.slug)}
                          mustAdaptToAnswerCardImages={rowHasImage}
                        >
                          <Label isCentered={rowHasImage}>
                            {answer.label}
                            {answer.subLabel && <SubLabel>{` ${answer.subLabel}`}</SubLabel>}
                          </Label>
                        </AnswerCard>
                      );
                    })}
                  </CardsContainer>
                </Row2>
              </SmallContent>
            </StepContainer>
          </TreeRow>
        );
      })}
    </>
  );
};

SofaPartAnswersSelector.propTypes = {
  part: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  answers: PropTypes.shape({}).isRequired,
  toggleAnswer: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default SofaPartAnswersSelector;
