import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { mobileThresholdPixels, colors } from './styledComponents';
import { Button } from './home/v2/styledComponents';
import { StepContainer as StepContainerV3, ComponentsContainer } from './home/v3/styledComponents';
import routesMap from '../Routes';
import withOrderContext from '../withOrderContext';
import tree, { multipleQuestions, treeAlterationSuit, treeSofaCreations } from './order/tree';
import ArrowButton from './order/LogoButton';
import OrderSummaryContainer from './order/OrderSummaryContainer';
import Step1Form from './order/Step1Form';
import { getTreeLocations, getTreeProblems } from '../services/treeSelector';
import { pushToLayer } from '../services/googleTagManager';
import initFullStory from '../services/fullStory';
import getInitialState, {
  getList, initialState, embroideries, linensAlterations, linensSizes, linensWhere,
} from './step1InitialState';
import treeUpcycling, { treeUpcyclingInverse } from './order/treeUpcycling';

const StepContainer1 = styled.div`
  display: block;
  background-color: ${colors.lightGrey};
  `;

const StepContainer2 = styled(StepContainerV3)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 45px auto auto;
  }
`;

const Content = styled.div`
  display: block;
  margin: auto;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  ${(props) => !props.isAvailable && `
    border-color: ${({ theme }) => theme.colors.lightGrey3};
    color: ${({ theme }) => theme.colors.lightGrey3};
    pointer-events: none;
    cursor: unset;`}

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    ${(props) => props.mobileOrder && 'margin-top: 0px;'}
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const scrollToStep = (ref, withTimeout = false) => {
  const scrollProps = {
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  };
  if (withTimeout) setTimeout(() => ref.scrollIntoView(scrollProps), 200);
  else ref.scrollIntoView(scrollProps);
};

const shouldDisplayLining = (selectedProblems) => {
  if (!selectedProblems) return false;

  const selectedProblemsToCheckForLining = Object.keys(selectedProblems)
    .filter((problem) => problem !== 'talkRDV')
    .map((problem) => selectedProblems[problem]);

  return selectedProblemsToCheckForLining.length > 0
    ? selectedProblemsToCheckForLining.reduce((acc, selectedProblem) => acc
      && selectedProblem.length > 0
      && (typeof selectedProblem[0] !== 'object' || selectedProblem[0].value !== -1),
    true)
    : false;
};

const getIsSelectingEmbroideryDone = ({ embroideryList, isDrawingEasy, activeEmbroideryFontSize }) => {
  if (embroideryList[2].isActive && !embroideryList[1].isActive) return isDrawingEasy !== undefined;
  if (embroideryList[1].isActive && !embroideryList[2].isActive) return activeEmbroideryFontSize !== -1;
  if (embroideryList[2].isActive && embroideryList[1].isActive) return isDrawingEasy !== undefined && activeEmbroideryFontSize !== -1;
  return embroideryList[0].isActive || embroideryList[3].isActive;
};

const getIsSelectedUpcyclingDone = (upcyclingCategories, upcyclingItems) => {
  if (upcyclingCategories.length === 0 || upcyclingItems.length === 0) return false;
  const hasAtLeastOneItemPerCategorySelected = upcyclingCategories.reduce((acc, upcyclingCategory) => {
    if (acc) {
      for (let i = 0; i < upcyclingItems.length; i += 1) {
        if (treeUpcyclingInverse[upcyclingItems[i].id].categories.includes(upcyclingCategory)) return true;
      }
    }
    return false;
  }, true);
  return hasAtLeastOneItemPerCategorySelected;
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    const { orderContext: { rdv3ClicksOrder, editingCloth } } = this.props;
    if (rdv3ClicksOrder && rdv3ClicksOrder.knowHow && rdv3ClicksOrder.knowHow.length > 0) {
      this.state = { ...getInitialState(), selectedCategory: 'RDV3Clicks' };
    } else {
      this.state = editingCloth ? {
        ...getInitialState(props.initialState),
        ...editingCloth,
      } : getInitialState(props.initialState);
    }

    pushToLayer({ funnel_type: 'standard', is_logged_in: false });
    if (process.env.GATSBY_ENV === 'PROD') initFullStory();
  }

  componentDidMount() {
    const {
      selectedCategory, selectedTypeOfWork, selectedCloth, selectedPiece, selectedFabric,
    } = this.state;
    const { orderContext: { clothes = [] } } = this.props;
    if (['clothes', 'deco'].includes(selectedCategory)) {
      if (selectedFabric || ['cost', 'pull', 'mari', 'ling', 'mail'].includes(selectedCloth)) {
        scrollToStep(this.Step1AdditionalInformation, true);
      } else if (selectedCloth || selectedPiece) {
        scrollToStep(this.Step1Fabrics, true);
      } else if (selectedTypeOfWork) {
        scrollToStep(this.Step1Piece, true);
      } else {
        scrollToStep(this.Step1TypeOfWork, true);
      }
    } else if (selectedCategory === 'RDV3Clicks') {
      scrollToStep(this.Step1Top, true);
    } else if (clothes.length > 0) {
      this.setDisplayButtons(true);
    }
  }

  setDisplayButtons = (displayButtons) => {
    this.setState({ displayButtons });
  }

  setEditingCloth = () => {
    const { orderContext: { editingCloth } } = this.props;
    this.setState({
      ...getInitialState(),
      ...editingCloth,
    });
  }

  setSelect = (selectedValue, stateName, isQuestionInsideState = false, index = -1) => {
    const { state } = this;
    if (!isQuestionInsideState) {
      this.setState({ [stateName]: selectedValue });
    } else {
      const list = state[stateName].slice();
      const question = { ...list[index].question };
      question.answer = selectedValue;
      list[index].question = { ...question };
      this.setState({ [stateName]: [...list] });
    }
  }

  setSingleChoice = (active, stateName, isList = false) => {
    const { state } = this;
    if (isList) {
      const list = state[stateName].slice();
      const newList = list.map((item, index) => ({ ...item, isActive: index === active }));
      this.setState({ [stateName]: [...newList] });
    } else {
      this.setState({ [stateName]: active });
      if (stateName === 'activeEmbroideryDrawingSize') {
        this.setState({ isDrawingFull: undefined });
      } else if (stateName === 'isDrawingFull') {
        this.setState({ isDrawingEasy: undefined });
      }
    }
  }

  setEmbroiderySingleChoiceForSuit = (active, stateName, suitPiece) => {
    const { suitPiecesEmbroideries: suitPiecesEmbroideriesState } = this.state;
    const suitPiecesEmbroideries = { ...suitPiecesEmbroideriesState };
    suitPiecesEmbroideries[suitPiece][stateName] = active;

    if (stateName === 'activeEmbroideryDrawingSize') {
      suitPiecesEmbroideries[suitPiece].isDrawingFull = undefined;
    } else if (stateName === 'isDrawingFull') {
      suitPiecesEmbroideries[suitPiece].isDrawingEasy = undefined;
    }

    this.setState({ suitPiecesEmbroideries });
  }

  setEmbroideryMultipleChoicesForSuit = (index, stateName, suitPiece) => {
    const { suitPiecesEmbroideries: suitPiecesEmbroideriesState } = this.state;
    const suitPiecesEmbroideries = { ...suitPiecesEmbroideriesState };
    suitPiecesEmbroideries[suitPiece][stateName][index].isActive = !suitPiecesEmbroideries[suitPiece][stateName][index].isActive;

    if (!suitPiecesEmbroideries[suitPiece][stateName][index].isActive) {
      if (index === 1) {
        suitPiecesEmbroideries[suitPiece].activeEmbroideryFontSize = -1;
      } else if (index === 2) {
        suitPiecesEmbroideries[suitPiece].activeEmbroideryDrawingSize = -1;
        suitPiecesEmbroideries[suitPiece].isDrawingFull = undefined;
        suitPiecesEmbroideries[suitPiece].isDrawingEasy = undefined;
      }
    }

    this.setState({ suitPiecesEmbroideries });
  }

  setMultipleChoices = (index, listName) => {
    const { state } = this;
    const newList = state[listName].slice();
    newList[index].isActive = !newList[index].isActive;
    this.setState({ [listName]: [...newList] });

    if (listName === 'curtainAlterations' && !newList[index].isActive) {
      if (index === 0) {
        this.setState({ activeCurtainAdjustment: -1 });
      }
    }

    if (listName === 'cushionAlterations') {
      if (newList[index].isActive) {
        this.setState({ isCushionAlterationSelected: true });
      } else {
        const isCushionAlterationSelected = newList.reduce((acc, alteration) => acc || alteration.isActive, false);
        this.setState({ isCushionAlterationSelected });
      }
    }

    if (listName === 'embroideries' && !newList[index].isActive) {
      if (index === 1) {
        this.setState({
          activeEmbroideryFontSize: -1,
        });
      } else if (index === 2) {
        this.setState({
          activeEmbroideryDrawingSize: -1,
          isDrawingFull: undefined,
          isDrawingEasy: undefined,
        });
      }
    }

    if (listName === 'linensProblems' && !newList[index].isActive) {
      if (index === 0) {
        this.setState({
          linensAlterations: getList(linensAlterations),
        });
      } else {
        this.setState({
          linensSizes: getList(linensSizes),
          linensWhere: getList(linensWhere),
        });
      }
    }
  }

  setSelectValue = (selectedValue, slug) => {
    const { selectedProblems: selectedProblemsState } = this.state;
    const selectedProblems = { ...selectedProblemsState };
    selectedProblems[slug] = [selectedValue];
    this.setState({ selectedProblems });
  }

  getClothesForSummaryWithCurrent = () => {
    const { orderContext: { clothes = [], editingClothIndex } } = this.props;
    if (typeof editingClothIndex === 'number') {
      return clothes.map((cloth, index) => index === editingClothIndex ? this.state : cloth);
    }
    return [...clothes, { ...this.state, isCurrent: true }];
  }

  getClothesForSummaryWithoutCurrent = () => {
    const { orderContext: { clothes = [] } } = this.props;
    return clothes;
  }

  setUpcyclingItemQuantity = (itemId, quantity) => {
    const { upcyclingItems: upcyclingItemsState } = this.state;
    const upcyclingItems = upcyclingItemsState.map((upcyclingItem) => {
      if (itemId !== upcyclingItem.id) return upcyclingItem;
      return {
        ...upcyclingItem,
        quantity,
      };
    });
    this.setState({ upcyclingItems });
  }

  setSuitUpcyclingItemQuantity = (suitPiece, itemId, quantity) => {
    const { suitPiecesUpcycling: suitPiecesUpcyclingState } = this.state;
    const suitPiecesUpcycling = { ...suitPiecesUpcyclingState };
    suitPiecesUpcycling[suitPiece].upcyclingItems = suitPiecesUpcycling[suitPiece].upcyclingItems.map((upcyclingItem) => {
      if (itemId !== upcyclingItem.id) return upcyclingItem;
      return {
        ...upcyclingItem,
        quantity,
      };
    });
    this.setState({ suitPiecesUpcycling });
  }

  setSuitItemQuantity = (quantity, itemId) => {
    const { suitItems: suitItemsState } = this.state;
    const suitItems = suitItemsState.map((suitItem) => {
      if (itemId !== suitItem.id) return suitItem;
      return {
        ...suitItem,
        quantity,
      };
    });
    this.setState({ suitItems });
  }

  setSofaPartNumber = (sofaPart, value) => {
    const {
      sofaPartsNumber: sofaPartsNumberState, selectedCategory, selectedCloth, selectedTypeOfWork, creationSlug,
      sofaParts, sofaPartsSubTypes, sofaPartsItems,
    } = this.state;
    const sofaPartsNumber = { ...sofaPartsNumberState };
    sofaPartsNumber[sofaPart] = value;
    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth,
      selectedTypeOfWork,
      creationSlug,
      sofaParts,
      sofaPartsSubTypes,
      sofaPartsItems,
      sofaPartsNumber,
    });
  }

  selectCreationOptions = (stateName, value) => {
    const {
      creationSize, creationShape, creationHeight, selectedCreationNumberOfPieces, creationFirstOption, creationSecondOption,
      selectedCategory, selectedCloth, selectedFabric, isFurSelected, selectedPiece, selectedTypeOfWork, creationSlug,
    } = this.state;
    let newCreationSlug = stateName === 'creationSlug' ? value : creationSlug;
    let newCreationSize = stateName === 'creationSize' ? value : creationSize;
    const newCreationShape = stateName === 'creationShape' ? value : creationShape;
    const newCreationHeight = stateName === 'creationHeight' ? value : creationHeight;
    const newSelectedCreationNumberOfPieces = stateName === 'selectedCreationNumberOfPieces'
      ? value : selectedCreationNumberOfPieces;
    let newCreationFirstOption = stateName === 'creationFirstOption' ? value : creationFirstOption;
    let newCreationSecondOption = stateName === 'creationSecondOption' ? value : creationSecondOption;
    if (stateName === 'setChairSelection') {
      newCreationSlug = value.creationSlug; newCreationSize = value.creationSize;
      newCreationFirstOption = value.creationFirstOption; newCreationSecondOption = value.creationSecondOption;
    }
    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth,
      selectedFabric,
      isFurSelected,
      selectedPiece,
      selectedTypeOfWork,
      creationSlug: newCreationSlug,
      creationShape: newCreationShape,
      selectedCreationNumberOfPieces: newSelectedCreationNumberOfPieces,
      creationSize: newCreationSize,
      creationHeight: newCreationHeight,
      creationFirstOption: newCreationFirstOption,
      creationSecondOption: newCreationSecondOption,
    });
  }

  saveCloth = () => {
    const { orderContext: { addOrEditCloth } } = this.props;
    if (this.isSelectingDone()) {
      const {
        selectedCategory, selectedTypeOfWork, selectedCloth, selectedPiece, selectedFabric, isFurSelected,
        creationSlug, selectedCreationNumberOfPieces, creationHeight,
        creationShape, creationSize, creationFirstOption, creationSecondOption,
        selectedProblems, selectedLining,
        embroideries: embroideriesState, activeEmbroideryFontSize,
        activeEmbroideryDrawingSize, isDrawingFull, isDrawingEasy,
        numberOfPieces,
        isCurtainLined, activeCurtainAdjustment,
        isCushionSmall, cushionHasZip, isCushionAlterationSelected,
        curtainAlterations: curtainAlterationsState, cushionAlterations: cushionAlterationsState,
        linensProblems: linensProblemsState, linensAlterations: linensAlterationsState,
        linensSizes: linensSizesState, linensWhere: linensWhereState,
        upcyclingCategories, upcyclingItems,
        suitPieces, suitProblems, suitItems, suitPieceLinings, suitPiecesEmbroideries, suitPiecesUpcycling,
        sofaParts, sofaPartsSubTypes, sofaPartsItems, sofaPartsNumber,
      } = this.state;
      addOrEditCloth({
        selectedCategory,
        selectedTypeOfWork,
        selectedCloth,
        selectedPiece,
        selectedFabric,
        isFurSelected,
        creationSlug,
        creationShape,
        selectedCreationNumberOfPieces,
        creationSize,
        creationHeight,
        creationFirstOption,
        creationSecondOption,
        selectedProblems,
        selectedLining,
        embroideries: embroideriesState,
        activeEmbroideryFontSize,
        activeEmbroideryDrawingSize,
        isDrawingFull,
        isDrawingEasy,
        numberOfPieces,
        isCurtainLined,
        activeCurtainAdjustment,
        isCushionSmall,
        cushionHasZip,
        isCushionAlterationSelected,
        curtainAlterations: curtainAlterationsState,
        cushionAlterations: cushionAlterationsState,
        linensProblems: linensProblemsState,
        linensAlterations: linensAlterationsState,
        linensSizes: linensSizesState,
        linensWhere: linensWhereState,
        upcyclingCategories,
        upcyclingItems,
        suitPieces,
        suitProblems,
        suitItems,
        suitPieceLinings,
        suitPiecesEmbroideries,
        suitPiecesUpcycling,
        sofaParts,
        sofaPartsSubTypes,
        sofaPartsItems,
        sofaPartsNumber,
      });
    }
  }

  goNext = () => {
    this.saveCloth();
    this.setState({
      ...getInitialState(),
    });
    navigate(routesMap.Step2.url);
  }

  addPiece = () => {
    pushToLayer({ event: 'Order Funnel - Extra Item Button Clicked' });
    this.saveCloth();
    this.setState(getInitialState());
    window.scrollTo(0, 0);
  }

  selectCategory = (selectedCategory) => {
    this.setState({
      ...initialState,
      embroideries: getList(embroideries),
      selectedCategory,
    }, () => {
      if (selectedCategory !== 'RDV3Clicks' && selectedCategory !== '') {
        pushToLayer({ funnel_type: 'standard' });
        pushToLayer({
          event: 'Order Funnel - Category Chosen',
          category: selectedCategory === 'clothes' ? 'mode' : 'décoration',
        });
        scrollToStep(this.Step1TypeOfWork);
      } else {
        pushToLayer({
          event: 'Order Funnel Started',
          from: window.location.pathname,
          from_interaction: 'lite_button',
          funnel_type: 'lite',
        });
        const { orderContext: { setRDV3ClicksOrder } } = this.props;
        setRDV3ClicksOrder([], [], [], 0, false);
        window.scrollTo(0, 0);
      }
    });
  }

  selectCloth = (slug) => {
    const { selectedCategory, selectedTypeOfWork } = this.state;
    pushToLayer({
      event: 'Order Funnel - Item Chosen',
      item: slug,
    });
    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth: slug,
      selectedTypeOfWork,
    }, () => scrollToStep(this.Step1Fabrics));
  }

  selectPiece = (selectedPiece) => {
    const { selectedCategory, selectedTypeOfWork } = this.state;
    pushToLayer({
      event: 'Order Funnel - Item Chosen',
      item: selectedPiece,
    });
    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedPiece,
      numberOfPieces: 1,
      selectedTypeOfWork,
    }, () => scrollToStep(this.Step1Fabrics));
  }

  selectFabric = (slug) => {
    const {
      selectedCategory, selectedTypeOfWork, selectedCloth, isFurSelected,
    } = this.state;
    pushToLayer({
      event: 'Order Funnel - Fabric Chosen',
      fabric: slug,
    });
    if (slug === 'fur') {
      this.setState({ isFurSelected: true });
    } else {
      this.setState({
        ...getInitialState(),
        selectedCategory,
        selectedCloth,
        selectedFabric: slug,
        isFurSelected,
        selectedTypeOfWork,
      }, () => scrollToStep(this.Step1AdditionalInformation));
    }
  }

  selectTypeOfWork = (slug) => {
    const { selectedCategory } = this.state;
    pushToLayer({
      event: 'Order Funnel - Order Type Chosen',
      order_type: slug,
    });
    pushToLayer({
      event: 'Order Funnel - First Question Displayed',
    });

    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedTypeOfWork: slug,
    }, () => scrollToStep(this.Step1Piece));
  }

  selectAccessoryCreation = (slug) => {
    const { selectedCategory, selectedTypeOfWork } = this.state;
    this.setState({
      ...getInitialState(),
      creationSlug: slug,
      selectedCategory,
      selectedTypeOfWork,
    }, () => scrollToStep(this.Step1Fabrics));
  }

  toggleSuitPiece = (suitPiece) => {
    const {
      suitPieces: suitPiecesState, suitProblems: suitProblemsState, suitItems: suitItemsState, suitPieceLinings: suitPieceLiningsState,
      suitPiecesEmbroideries: suitPiecesEmbroideriesState, suitPiecesUpcycling: suitPiecesUpcyclingState,
      selectedCategory, selectedCloth, selectedFabric, isFurSelected, selectedTypeOfWork,
    } = this.state;
    let suitPieces = suitPiecesState.slice();
    let suitProblems = suitProblemsState.slice();
    let suitItems = suitItemsState.slice();
    const suitPieceLinings = { ...suitPieceLiningsState };
    const suitPiecesEmbroideries = { ...suitPiecesEmbroideriesState };
    const suitPiecesUpcycling = { ...suitPiecesUpcyclingState };
    if (suitPieces.includes(suitPiece)) {
      suitPieces = suitPieces.filter((piece) => piece !== suitPiece);
      suitProblems = suitProblems.filter((suitProblem) => !suitProblem.includes(`${suitPiece}_`));
      suitItems = suitItems.filter((suitItem) => !suitItem.slug.includes(`cost_${suitPiece}_`));
      delete suitPiecesEmbroideries[suitPiece];
      delete suitPiecesUpcycling[suitPiece];
      delete suitPieceLinings[suitPiece];
    } else {
      suitPiecesEmbroideries[suitPiece] = {
        embroideries: getList(embroideries),
        activeEmbroideryFontSize: -1,
        activeEmbroideryDrawingSize: -1,
        isDrawingFull: undefined,
        isDrawingEasy: undefined,
      };
      suitPiecesUpcycling[suitPiece] = {
        upcyclingCategories: [],
        upcyclingItems: [],
      };
      suitPieces.push(suitPiece);
    }
    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth,
      selectedFabric,
      isFurSelected,
      selectedTypeOfWork,
      suitPiecesEmbroideries,
      suitPiecesUpcycling,
      suitPieces,
      suitProblems,
      suitItems,
      suitPieceLinings,
    });
  }

  toggleSuitProblem = (suitPiece, suitProblem) => {
    const { suitProblems: suitProblemsState, suitItems: suitItemsState, suitPieceLinings: suitPieceLiningsState } = this.state;
    let suitProblems = suitProblemsState.slice();
    let suitItems = suitItemsState.slice();
    const suitPieceLinings = { ...suitPieceLiningsState };
    const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
    if (suitProblems.includes(suitProblem)) {
      suitProblems = suitProblems.filter((problem) => problem !== suitProblem);
      if (suitProblems.length === 0) {
        suitItems = [];
        delete suitPieceLinings[suitPiece];
      } else {
        suitItems = suitItems.filter(({ piece, problem }) => (piece !== suitPiece || problem !== suitProblemSlug));
        if (suitItems.filter(({ piece }) => piece === suitPiece).length === 0) delete suitPieceLinings[suitPiece];
      }
    } else {
      suitProblems.push(suitProblem);
      if (treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug].answers?.length === 1) {
        suitItems.push({
          ...treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug].answers[0],
          piece: suitPiece,
          problem: suitProblemSlug,
        });
      }
    }

    this.setState({ suitProblems, suitItems, suitPieceLinings });
  }

  toggleSuitItem = (suitPiece, suitProblem, item) => {
    const { suitItems: suitItemsState, suitPieceLinings: suitPieceLiningsState } = this.state;
    let suitItems = suitItemsState.slice();
    const suitPieceLinings = { ...suitPieceLiningsState };
    if (suitItems.find(({ id }) => id === item.id)) {
      suitItems = suitItems.filter(({ id }) => id !== item.id);
      if (suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblem).length === 0) delete suitPieceLinings[suitPiece];
    } else {
      suitItems.push({
        ...item,
        piece: suitPiece,
        problem: suitProblem,
      });
      if (item.isMultiple) suitItems[suitItems.length - 1].quantity = { value: 1, label: '1' };
    }
    this.setState({ suitItems, suitPieceLinings });
  }

  toggleSofaPart = (sofaPart) => {
    const {
      sofaParts: sofaPartsState, sofaPartsSubTypes: sofaPartsSubTypesState, sofaPartsItems: sofaPartsItemsState,
      sofaPartsNumber: sofaPartsNumberState, selectedCategory, selectedCloth, selectedTypeOfWork, creationSlug,
    } = this.state;
    let sofaParts = sofaPartsState.slice();
    const sofaPartsSubTypes = { ...sofaPartsSubTypesState };
    const sofaPartsItems = { ...sofaPartsItemsState };
    const sofaPartsNumber = { ...sofaPartsNumberState };

    if (sofaParts.includes(sofaPart)) {
      sofaParts = sofaParts.filter((part) => part !== sofaPart);
      delete sofaPartsSubTypes[sofaPart];
      delete sofaPartsItems[sofaPart];
      delete sofaPartsNumber[sofaPart];
    } else {
      sofaParts.push(sofaPart);
      sofaPartsSubTypes[sofaPart] = {};
      sofaPartsItems[sofaPart] = {};
      sofaPartsNumber[sofaPart] = 1;
    }

    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth,
      selectedTypeOfWork,
      creationSlug,
      sofaParts,
      sofaPartsSubTypes,
      sofaPartsItems,
      sofaPartsNumber,
    });
  }

  toggleSofaPartSubType = (sofaPart, slug, subType) => {
    const {
      sofaPartsSubTypes: sofaPartsSubTypesState, sofaParts, sofaPartsItems, sofaPartsNumber,
      selectedCategory, selectedCloth, selectedTypeOfWork, creationSlug,
    } = this.state;
    const sofaPartsSubTypes = { ...sofaPartsSubTypesState };

    sofaPartsSubTypes[sofaPart][slug] = subType;

    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth,
      selectedTypeOfWork,
      creationSlug,
      sofaParts,
      sofaPartsItems,
      sofaPartsNumber,
      sofaPartsSubTypes,
    });
  }

  toggleSofaPartItem = (sofaPart, slug, item) => {
    const {
      sofaPartsItems: sofaPartsItemsState, sofaParts, sofaPartsSubTypes, sofaPartsNumber,
      selectedCategory, selectedCloth, selectedTypeOfWork, creationSlug,
    } = this.state;
    const sofaPartsItems = { ...sofaPartsItemsState };

    sofaPartsItems[sofaPart][slug] = item;

    this.setState({
      ...getInitialState(),
      selectedCategory,
      selectedCloth,
      selectedTypeOfWork,
      creationSlug,
      sofaParts,
      sofaPartsSubTypes,
      sofaPartsNumber,
      sofaPartsItems,
    });
  }

  selectProblems = (problemsSlug, locationsTree = {}) => {
    const { selectedProblems } = this.state;
    let newSelectedProblems = { ...selectedProblems };
    const locationsSlugs = newSelectedProblems[problemsSlug];
    const { locations = [] } = locationsTree;
    if (typeof locationsSlugs === 'object') {
      newSelectedProblems[problemsSlug].forEach((location) => {
        if (Object.keys(newSelectedProblems).includes(`${location}_multiple`)) {
          delete newSelectedProblems[`${location}_multiple`];
        }
      });
      delete newSelectedProblems[problemsSlug];
      if (!shouldDisplayLining(newSelectedProblems)) {
        this.setState({ selectedLining: '' });
      }
    } else {
      let newLocations = [];
      if (problemsSlug.includes('acce')) newLocations = [{ value: 1, label: '1' }];
      if (locations.length === 1) newLocations = [locations[0].slug];
      newSelectedProblems = {
        ...newSelectedProblems,
        [problemsSlug]: newLocations,
      };
    }
    this.setState({
      selectedProblems: newSelectedProblems,
    });
  }

  selectLocations = (problemSlug, locationSlug) => {
    const { selectedProblems: selectedProblemsState } = this.state;
    let selectedProblems = { ...selectedProblemsState };
    const slugIndex = selectedProblems[problemSlug].findIndex((location) => location === locationSlug);
    if (slugIndex >= 0) {
      selectedProblems[problemSlug].splice(slugIndex, 1);
      if (Object.keys(multipleQuestions).includes(locationSlug)) {
        delete selectedProblems[`${locationSlug}_multiple`];
      }
      if (!shouldDisplayLining(selectedProblems)) {
        this.setState({ selectedLining: '' });
      }
    } else {
      selectedProblems[problemSlug].push(locationSlug);
      if (Object.keys(multipleQuestions).includes(locationSlug)) {
        selectedProblems = { ...selectedProblems, [`${locationSlug}_multiple`]: [{ value: 1, label: '1' }] };
      }
    }
    this.setState({
      selectedProblems: { ...selectedProblems },
    });
  }

  toggleUpcyclingCategory = (slug) => {
    const { upcyclingCategories: upcyclingCategoriesState, upcyclingItems: upcyclingItemsState } = this.state;
    let upcyclingCategories = upcyclingCategoriesState.slice();
    let upcyclingItems = upcyclingItemsState.slice();
    if (upcyclingCategories.includes(slug)) {
      upcyclingCategories = upcyclingCategories.filter((upcyclingCategory) => upcyclingCategory !== slug);
      if (upcyclingCategories.length === 0) {
        upcyclingItems = [];
      } else {
        upcyclingItems = upcyclingItems.filter(({ id }) => {
          const itemCategories = treeUpcyclingInverse[id].categories;
          if (itemCategories.includes(slug)) {
            if (itemCategories.length === 1) return false;
            const mustBeRemoved = upcyclingCategories.reduce((acc, upcyclingCategory) => acc && itemCategories.includes(upcyclingCategory), true);
            return mustBeRemoved;
          }
          return true;
        });
      }
    } else {
      upcyclingCategories.push(slug);
    }
    this.setState({ upcyclingCategories, upcyclingItems });
  }

  toggleUpcyclingItem = (item) => {
    const { upcyclingItems: upcyclingItemsState } = this.state;
    let upcyclingItems = upcyclingItemsState.slice();
    if (upcyclingItems.find(({ id }) => id === item.id)) {
      upcyclingItems = upcyclingItems.filter(({ id }) => id !== item.id);
    } else {
      upcyclingItems.push(item);
      if (item.isMultiple) upcyclingItems[upcyclingItems.length - 1].quantity = { value: 1, label: '1' };
    }
    this.setState({ upcyclingItems });
  }

  toggleSuitUpcyclingCategory = (suitPiece, slug) => {
    const { suitPiecesUpcycling: suitPiecesUpcyclingState } = this.state;
    const suitPiecesUpcycling = { ...suitPiecesUpcyclingState };
    if (suitPiecesUpcycling[suitPiece].upcyclingCategories.includes(slug)) {
      suitPiecesUpcycling[suitPiece].upcyclingCategories = suitPiecesUpcycling[suitPiece].upcyclingCategories.filter((upcyclingCategory) => upcyclingCategory !== slug);
      if (suitPiecesUpcycling[suitPiece].upcyclingCategories.length === 0) {
        suitPiecesUpcycling[suitPiece].upcyclingItems = [];
      } else {
        suitPiecesUpcycling[suitPiece].upcyclingItems = suitPiecesUpcycling[suitPiece].upcyclingItems.filter(({ id }) => {
          const itemCategories = treeUpcyclingInverse[id].categories;
          if (itemCategories.includes(slug)) {
            if (itemCategories.length === 1) return false;
            const mustBeRemoved = suitPiecesUpcycling[suitPiece].upcyclingCategories.reduce((acc, upcyclingCategory) => acc && itemCategories.includes(upcyclingCategory), true);
            return mustBeRemoved;
          }
          return true;
        });
      }
    } else {
      suitPiecesUpcycling[suitPiece].upcyclingCategories.push(slug);
    }
    this.setState({ suitPiecesUpcycling });
  }

  toggleSuitUpcyclingItem = (suitPiece, item) => {
    const { suitPiecesUpcycling: suitPiecesUpcyclingState } = this.state;
    const suitPiecesUpcycling = { ...suitPiecesUpcyclingState };

    if (suitPiecesUpcycling[suitPiece].upcyclingItems.find(({ id }) => id === item.id)) {
      suitPiecesUpcycling[suitPiece].upcyclingItems = suitPiecesUpcycling[suitPiece].upcyclingItems.filter(({ id }) => id !== item.id);
    } else {
      suitPiecesUpcycling[suitPiece].upcyclingItems.push(item);
      if (item.isMultiple) suitPiecesUpcycling[suitPiece].upcyclingItems[suitPiecesUpcycling[suitPiece].upcyclingItems.length - 1].quantity = { value: 1, label: '1' };
    }
    this.setState({ suitPiecesUpcycling });
  }

  selectLining = (haveLining) => {
    this.setState({
      selectedLining: haveLining,
    });
  }

  selectSuitPieceLining = (piece, haveLining) => {
    const { suitPieceLinings: suitPieceLiningsState } = this.state;
    const suitPieceLinings = { ...suitPieceLiningsState };
    suitPieceLinings[piece] = haveLining;
    this.setState({
      suitPieceLinings,
    });
  }

  isSelectingEmbroideryDone = () => {
    const {
      embroideries: embroideriesState, isDrawingEasy, activeEmbroideryFontSize, suitPieces, suitPiecesEmbroideries,
    } = this.state;

    if (suitPieces.length > 0) {
      return suitPieces.reduce((isDone, suitPiece) => isDone && getIsSelectingEmbroideryDone({
        embroideryList: suitPiecesEmbroideries[suitPiece].embroideries,
        isDrawingEasy: suitPiecesEmbroideries[suitPiece].isDrawingEasy,
        activeEmbroideryFontSize: suitPiecesEmbroideries[suitPiece].activeEmbroideryFontSize,
      }), true);
    }

    return getIsSelectingEmbroideryDone({ embroideryList: embroideriesState, isDrawingEasy, activeEmbroideryFontSize });
  }

  isSelectingClothesDone = () => {
    const {
      selectedCloth, selectedLining, selectedProblems, suitPieces, suitProblems, suitPieceLinings,
    } = this.state;
    if (selectedCloth === 'cost') {
      if (suitPieces.length === 0) return false;
      for (let i = 0; i < suitPieces.length; i += 1) {
        const suitProblemsForCurrentPiece = suitProblems.filter((suitProblem) => suitProblem.includes(`${suitPieces[i]}_`));
        if (suitProblemsForCurrentPiece.length === 0
          || !(suitPieceLinings[suitPieces[i]]
            || (suitProblemsForCurrentPiece.length === 1 && suitProblemsForCurrentPiece[0].includes('talkRDV')))) return false;
      }
      return true;
    }
    if (selectedCloth === 'acce') {
      if (Object.keys(selectedProblems).length === 0) return false;
      if (selectedProblems.damaged) return selectedProblems.damaged.length > 0;
      return true;
    }
    return (Object.keys(selectedProblems).length === 1 && Object.keys(selectedProblems)[0] === 'talkRDV')
      || selectedLining !== '';
  }

  isSelectingCurtainsDone = () => {
    const { curtainAlterations: alterations, activeCurtainAdjustment } = this.state;

    if (alterations[0].isActive) {
      return activeCurtainAdjustment !== -1;
    }
    for (let i = 1; i < 3; i += 1) {
      if (alterations[i].isActive) return true;
    }
    return false;
  }

  isSelectingCushionsDone = () => {
    const { isCushionAlterationSelected } = this.state;
    return isCushionAlterationSelected;
  }

  isSelectingLinensDone = () => {
    const { linensProblems: problems, linensAlterations: alterations, linensWhere: where } = this.state;

    if (problems[0].isActive && problems[1].isActive) {
      return alterations.reduce((acc, alteration) => acc || alteration.isActive, false)
        && where.reduce((acc, location) => acc || location.isActive, false);
    } if (problems[0].isActive) {
      return alterations.reduce((acc, alteration) => acc || alteration.isActive, false);
    } if (problems[1].isActive) {
      return where.reduce((acc, location) => acc || location.isActive, false);
    }
    return false;
  }

  isSelectingUpcyclingDone = () => {
    const {
      selectedPiece, selectedCloth, selectedFabric, upcyclingCategories, upcyclingItems, suitPieces, suitPiecesUpcycling,
    } = this.state;
    let isDone = !!selectedPiece || (!!selectedCloth && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling'].includes(selectedCloth)));
    if (selectedCloth === 'cost' && suitPieces.length > 0) {
      if (suitPieces.length === 0) return false;
      return suitPieces.reduce((isSelectingSuitUpcyclingDone, suitPiece) => isSelectingSuitUpcyclingDone && getIsSelectedUpcyclingDone(
        suitPiecesUpcycling[suitPiece].upcyclingCategories,
        suitPiecesUpcycling[suitPiece].upcyclingItems,
      ), true);
    }
    if (!isDone) return false;
    const piece = selectedPiece || selectedCloth;
    isDone = !treeUpcycling[piece];
    if (isDone) return true;
    return getIsSelectedUpcyclingDone(upcyclingCategories, upcyclingItems);
  }

  isSelectingSofaCreationDone = () => {
    const { sofaParts, sofaPartsSubTypes, sofaPartsItems } = this.state;
    if (sofaParts.length === 0) return false;
    const hasRightNumberOfAnswers = sofaParts.reduce((acc, sofaPart) => {
      const treeSofaPart = treeSofaCreations.parts[sofaPart];
      return (acc
        && Object.keys(sofaPartsSubTypes[sofaPart]).length === treeSofaPart.subTypeQuestions.length
        && (!treeSofaPart.toppingQuestions || Object.keys(sofaPartsItems[sofaPart]).length === treeSofaPart.toppingQuestions.length));
    }, true);
    return hasRightNumberOfAnswers;
  }

  isSelectingDone = () => {
    const {
      selectedCategory, selectedPiece, selectedTypeOfWork,
      creationSecondOption, creationSlug,
    } = this.state;

    switch (selectedTypeOfWork) {
      case 'alteration':
        if (selectedCategory === 'clothes') return this.isSelectingClothesDone();
        if (selectedCategory === 'deco' && selectedPiece === 'ride') return this.isSelectingCurtainsDone();
        if (selectedCategory === 'deco' && selectedPiece === 'cous') return this.isSelectingCushionsDone();
        if (selectedCategory === 'deco' && selectedPiece === 'mais') return this.isSelectingLinensDone();
        break;
      case 'embroidery':
        return this.isSelectingEmbroideryDone();
      case 'transformation':
        return this.isSelectingUpcyclingDone();
      case 'creation':
        if (creationSlug === 'crea_sofa') return this.isSelectingSofaCreationDone();
        return Object.keys(creationSecondOption).length > 0;
      default:
        return false;
    }

    return false;
  }

  trackNeedsDescribed = (totalPaid) => {
    const { needsDescribed } = this.state;
    if (!needsDescribed) {
      this.setState({ needsDescribed: true });
      pushToLayer({
        event: 'Order Funnel - Needs Described',
        order_amount: Math.round(totalPaid * 100),
      });
    }
  }

  displaySummary = () => {
    const { orderContext: { clothes = [] } } = this.props;
    if (this.isSelectingDone()) {
      return (
        <OrderSummaryContainer
          clothes={this.getClothesForSummaryWithCurrent()}
          setEditingCloth={this.setEditingCloth}
          saveCloth={this.saveCloth}
          onTotalPaid={(totalPaid) => this.trackNeedsDescribed(totalPaid)}
        />
      );
    }
    if (clothes.length) {
      return (
        <OrderSummaryContainer
          canEdit
          clothes={this.getClothesForSummaryWithoutCurrent()}
          setEditingCloth={this.setEditingCloth}
          saveCloth={this.saveCloth}
        />
      );
    }
    return '';
  }

  renderButtons = () => {
    const { selectedCategory, displayButtons } = this.state;
    return selectedCategory !== 'RDV3Clicks' && (this.isSelectingDone() || displayButtons) && (
      <StepContainer2>
        <ComponentsContainer>
          <StyledButton onClick={this.addPiece} transparent isAvailable>
            + Ajouter une pièce
          </StyledButton>
          <StyledLink onClick={this.goNext}>
            <ArrowButton>OK, on passe au RDV !</ArrowButton>
          </StyledLink>
        </ComponentsContainer>
      </StepContainer2>
    );
  }

  render() {
    const {
      selectedCategory, selectedTypeOfWork,
      selectedCloth, selectedPiece, selectedFabric, isFurSelected,
      selectedProblems, selectedLining,
      creationSlug, creationShape, creationSize, creationFirstOption, creationSecondOption,
      selectedCreationNumberOfPieces, creationHeight,
      numberOfPieces,
      isCurtainLined, curtainAlterations: curtainAlterationsState, activeCurtainAdjustment,
      isCushionSmall, cushionHasZip, isCushionAlterationSelected, cushionAlterations: cushionAlterationsState,
      embroideries: embroideriesState, activeEmbroideryFontSize, activeEmbroideryDrawingSize, isDrawingFull, isDrawingEasy,
      linensProblems: linensProblemsState, linensAlterations: linensAlterationsState, linensSizes: linensSizesState, linensWhere: linensWhereState,
      upcyclingCategories, upcyclingItems, suitPieces, suitProblems, suitItems, suitPieceLinings, suitPiecesEmbroideries, suitPiecesUpcycling,
      sofaParts, sofaPartsSubTypes, sofaPartsItems, sofaPartsNumber,
    } = this.state;
    const { orderContext: { clothes, nbMasks, setNbMasks } } = this.props;
    const isLiningDisplayed = !['acce', 'cost'].includes(selectedCloth) ? shouldDisplayLining(selectedProblems) : false;
    return (
      <StepContainer1>
        <Content>
          <Step1Form
            selectedCategory={selectedCategory}
            selectedCloth={selectedCloth}
            selectedPiece={selectedPiece}
            selectedFabric={selectedFabric}
            isFurSelected={isFurSelected}
            selectedTypeOfWork={selectedTypeOfWork}
            creationSlug={creationSlug}
            creationShape={creationShape}
            selectedCreationNumberOfPieces={selectedCreationNumberOfPieces}
            creationSize={creationSize}
            creationHeight={creationHeight}
            creationFirstOption={creationFirstOption}
            creationSecondOption={creationSecondOption}
            selectedProblems={selectedProblems}
            selectedLining={selectedLining}
            isLiningDisplayed={isLiningDisplayed}
            setRef={(key, value) => { this[key] = value; }}
            selectCategory={this.selectCategory}
            selectCloth={this.selectCloth}
            selectPiece={this.selectPiece}
            selectFabric={this.selectFabric}
            selectTypeOfWork={this.selectTypeOfWork}
            selectAccessoryCreation={this.selectAccessoryCreation}
            selectCreationOptions={this.selectCreationOptions}
            selectProblems={this.selectProblems}
            treeProblems={getTreeProblems(tree, selectedCloth, selectedTypeOfWork)}
            selectLocations={this.selectLocations}
            getTreeLocations={(problemSlug) => getTreeLocations(tree, selectedCloth, selectedTypeOfWork, problemSlug)}
            setSelectValue={this.setSelectValue}
            selectLining={this.selectLining}
            setSelect={this.setSelect}
            setSingleChoice={this.setSingleChoice}
            setMultipleChoices={this.setMultipleChoices}
            numberOfPieces={numberOfPieces}
            isCurtainLined={isCurtainLined}
            curtainAlterations={curtainAlterationsState}
            activeCurtainAdjustment={activeCurtainAdjustment}
            isCushionSmall={isCushionSmall}
            cushionHasZip={cushionHasZip}
            isCushionAlterationSelected={isCushionAlterationSelected}
            cushionAlterations={cushionAlterationsState}
            embroideries={embroideriesState}
            activeEmbroideryFontSize={activeEmbroideryFontSize}
            activeEmbroideryDrawingSize={activeEmbroideryDrawingSize}
            isDrawingFull={isDrawingFull}
            isDrawingEasy={isDrawingEasy}
            displaySummary={!!this.displaySummary()}
            linensProblems={linensProblemsState}
            linensAlterations={linensAlterationsState}
            linensSizes={linensSizesState}
            linensWhere={linensWhereState}
            upcyclingCategories={upcyclingCategories}
            toggleUpcyclingCategory={this.toggleUpcyclingCategory}
            upcyclingItems={upcyclingItems}
            toggleUpcyclingItem={this.toggleUpcyclingItem}
            setUpcyclingItemQuantity={this.setUpcyclingItemQuantity}
            suitPieces={suitPieces}
            toggleSuitPiece={this.toggleSuitPiece}
            suitProblems={suitProblems}
            toggleSuitProblem={this.toggleSuitProblem}
            suitItems={suitItems}
            toggleSuitItem={this.toggleSuitItem}
            setSuitItemQuantity={this.setSuitItemQuantity}
            suitPieceLinings={suitPieceLinings}
            selectSuitPieceLining={this.selectSuitPieceLining}
            nbMasks={nbMasks}
            setNbMasks={setNbMasks}
            clothes={clothes}
            suitPiecesEmbroideries={suitPiecesEmbroideries}
            setEmbroiderySingleChoiceForSuit={this.setEmbroiderySingleChoiceForSuit}
            setEmbroideryMultipleChoicesForSuit={this.setEmbroideryMultipleChoicesForSuit}
            suitPiecesUpcycling={suitPiecesUpcycling}
            toggleSuitUpcyclingCategory={this.toggleSuitUpcyclingCategory}
            toggleSuitUpcyclingItem={this.toggleSuitUpcyclingItem}
            setSuitUpcyclingItemQuantity={this.setSuitUpcyclingItemQuantity}
            sofaParts={sofaParts}
            toggleSofaPart={this.toggleSofaPart}
            sofaPartsSubTypes={sofaPartsSubTypes}
            toggleSofaPartSubType={this.toggleSofaPartSubType}
            sofaPartsItems={sofaPartsItems}
            toggleSofaPartItem={this.toggleSofaPartItem}
            sofaPartsNumber={sofaPartsNumber}
            setSofaPartNumber={this.setSofaPartNumber}
          />

          {this.displaySummary()}
          {this.renderButtons()}
        </Content>
      </StepContainer1>
    );
  }
}

Step1.propTypes = {
  orderContext: PropTypes.shape({
    addOrEditCloth: PropTypes.func.isRequired,
    clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    editingCloth: PropTypes.shape({}),
    editingClothIndex: PropTypes.number,
    rdv3ClicksOrder: PropTypes.shape({
      knowHow: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    setRDV3ClicksOrder: PropTypes.func,
    nbMasks: PropTypes.number.isRequired,
    setNbMasks: PropTypes.func.isRequired,
  }).isRequired,
  initialState: PropTypes.shape({
    selectedCategory: PropTypes.string,
    selectedPiece: PropTypes.string,
    selectedCloth: PropTypes.string,
    selectedFabric: PropTypes.string,
    selectedTypeOfWork: PropTypes.string,
  }),
};

Step1.defaultProps = {
  initialState: {},
};

export default withOrderContext(Step1);
