import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  TreeRow,
  StepContainer,
  SmallContent,
  Question,
  customStyles,
  setTheme,
  Select,
  Label,
  Row2,
  AnswerCard,
  CardsContainer,
} from './orderStyledComponents';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import infoLogo from '../../assets/information.png';
import ProgressBarVertical from './ProgressBarVertical';

const Img = styled.img`
  margin-bottom: 8px;
  margin-left: 8px;
`;

const TooltipText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  color: ${colors.white};
  width: 215px;
  ${(props) => props.noMarginTop && 'margin-top: -10px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 175px;
    font-size: 12px;
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  background-color: ${colors.navy} !important;
  width: auto !important;
  height: auto !important;
`;

const Step1Curtains = ({
  createRange, setSelect, setSingleChoice, setMultipleChoices,
  numberOfPieces, isLined, alterations, activeAdjustment,
}) => (
  <div>
    <TreeRow>
      <StepContainer>
        <ProgressBarVertical
          isActive={numberOfPieces > 0}
        />
        <SmallContent marginBottom="30px" mobileNoMarginTop marginTop={0.01}>
          <StyledReactTooltip place="top" type="none" effect="float" id="numberOfPiecesTooltip">
            <TooltipText>Un rideau est égal à un pan de fenêtre. </TooltipText>
            <TooltipText noMarginTop>Une fenêtre a généralement 2 pans, donc 2 rideaux ;)</TooltipText>
          </StyledReactTooltip>
          <Question center>
            Sur combien de rideau doit-on faire les retouches ?
            <Img
              src={infoLogo}
              alt="Comment compter le nombre de rideaux ?"
              data-tip
              data-for="numberOfPiecesTooltip"
            />
          </Question>
          <Row2 noMarginBottomMobile>
            <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
              <Select
                placeholder=""
                value={{ label: numberOfPieces, value: numberOfPieces }}
                onChange={(selectedValue) => setSelect(selectedValue.value, 'numberOfPieces')}
                options={createRange(1, 20)}
                styles={customStyles}
                theme={(theme) => setTheme(theme)}
              />
            </CardsContainer>
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>

    {numberOfPieces > 0
      && (
        <TreeRow>
          <StepContainer>
            <ProgressBarVertical
              isActive={isLined !== undefined}
            />
            <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
              <StyledReactTooltip place="top" type="none" effect="float" id="linenTooltip">
                <TooltipText>
                  La doublure est le tissu situé en plus à l’intérieur du rideau souvent plus fin et occultant.
                </TooltipText>
              </StyledReactTooltip>
              <Question center>
                Ce sont des rideaux doublés ?
                <Img src={infoLogo} alt="Qu'est ce qu'une doublure ?" data-tip data-for="linenTooltip" />
              </Question>
              <Row2 noMarginBottomMobile>
                <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                  <AnswerCard isActive={isLined} onClick={() => setSingleChoice(true, 'isCurtainLined')}>
                    <Label>Oui</Label>
                  </AnswerCard>
                  <AnswerCard
                    isActive={isLined === false}
                    onClick={() => setSingleChoice(false, 'isCurtainLined')}
                  >
                    <Label>Non</Label>
                  </AnswerCard>
                </CardsContainer>
              </Row2>
            </SmallContent>
          </StepContainer>
        </TreeRow>
      )}

    {isLined !== undefined
      && (
        <div>
          <TreeRow>
            <StepContainer>
              <ProgressBarVertical
                isActive={(alterations.filter((alteration) => alteration.isActive === true).length > 0)}
                noLine={
                  !alterations.reduce((acc, alteration) => acc || alteration.isActive, false)
                  || ((alterations[1].isActive
                    || alterations[2].isActive
                    || alterations[3].isActive)
                    && (!alterations[0].isActive))
                }
              />
              <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
                <Question center>L’ajustement à faire :</Question>
                <Row2 noMarginBottomMobile>
                  <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                    {alterations.map((alteration, index) => (
                      <AnswerCard
                        key={alteration.label}
                        isActive={alteration.isActive}
                        onClick={() => setMultipleChoices(index, 'curtainAlterations')}
                      >
                        <Label>{alteration.label}</Label>
                      </AnswerCard>
                    ))}
                  </CardsContainer>
                </Row2>
              </SmallContent>
            </StepContainer>
          </TreeRow>

          {alterations[0].isActive
            && (
              <TreeRow>
                <StepContainer>
                  <ProgressBarVertical
                    isActive={activeAdjustment > -1}
                    noLine
                  />
                  <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
                    <Question center>De quelle taille sont vos rideaux ?</Question>
                    <Row2 noMarginBottomMobile>
                      <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                        {alterations[0].adjustments.map((alteration, index) => (
                          <AnswerCard
                            key={alteration.label}
                            isActive={index === activeAdjustment}
                            onClick={() => setSingleChoice(index, 'activeCurtainAdjustment')}
                          >
                            <Label>{alteration.label}</Label>
                          </AnswerCard>
                        ))}
                      </CardsContainer>
                    </Row2>
                  </SmallContent>
                </StepContainer>
              </TreeRow>
            )}

        </div>
      )}
  </div>
);

Step1Curtains.propTypes = {
  createRange: PropTypes.func.isRequired,
  setSelect: PropTypes.func.isRequired,
  setSingleChoice: PropTypes.func.isRequired,
  setMultipleChoices: PropTypes.func.isRequired,
  numberOfPieces: PropTypes.number,
  isLined: PropTypes.bool,
  alterations: PropTypes.arrayOf(PropTypes.shape({
    isActive: PropTypes.bool,
    adjustments: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  activeAdjustment: PropTypes.number.isRequired,
};

Step1Curtains.defaultProps = {
  isLined: undefined,
  numberOfPieces: undefined,
};

export default Step1Curtains;
