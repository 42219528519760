import React from 'react';
import PropTypes from 'prop-types';
import {
  TreeRow,
  StepContainer,
  SmallContent,
  Question,
  customStyles,
  setTheme,
  Select,
  Label,
  Row2,
  AnswerCard,
  CardsContainer,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';

const Step1Cushions = ({
  createRange, setSelect, setSingleChoice, setMultipleChoices,
  numberOfPieces, isSmall, hasZip, alterations, isAlterationSelected,
  isAccessoryCreation,
}) => (
  <div>
    <TreeRow>
      <StepContainer>
        <ProgressBarVertical
          isActive={numberOfPieces > 0}
        />
        <SmallContent marginBottom="30px" mobileNoMarginTop>
          <Question center>Combien de housses de coussin ?</Question>
          <Row2 noMarginBottomMobile>
            <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
              <Select
                placeholder=""
                value={{ label: numberOfPieces, value: numberOfPieces }}
                onChange={(selectedValue) => setSelect(selectedValue.value, 'numberOfPieces')}
                options={createRange(1, 10)}
                styles={customStyles}
                theme={(theme) => setTheme(theme)}
              />
            </CardsContainer>
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>

    {isAccessoryCreation && numberOfPieces > 0
      && (
        <TreeRow>
          <StepContainer>
            <ProgressBarVertical
              isActive={isSmall !== undefined}
            />
            <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
              <Question center>De quelle taille ?</Question>
              <Row2 noMarginBottomMobile>
                <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                  <AnswerCard isActive={isSmall} onClick={() => setSingleChoice(true, 'isCushionSmall')}>
                    <Label>Moins de 45 cm</Label>
                  </AnswerCard>
                  <AnswerCard
                    isActive={isSmall === false}
                    onClick={() => setSingleChoice(false, 'isCushionSmall')}
                  >
                    <Label>Plus de 45 cm</Label>
                  </AnswerCard>
                </CardsContainer>
              </Row2>
            </SmallContent>
          </StepContainer>
        </TreeRow>
      )}

    {isAccessoryCreation && numberOfPieces > 0 && isSmall !== undefined
      && (
        <TreeRow>
          <StepContainer>
            <ProgressBarVertical
              isActive={hasZip !== undefined}
              noLine
            />
            <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
              <Question center>Souhaitez-vous ajouter un zip ?</Question>
              <Row2 noMarginBottomMobile>
                <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                  <AnswerCard isActive={hasZip} onClick={() => setSingleChoice(true, 'cushionHasZip')}>
                    <Label>Oui</Label>
                  </AnswerCard>
                  <AnswerCard
                    isActive={hasZip === false}
                    onClick={() => setSingleChoice(false, 'cushionHasZip')}
                  >
                    <Label>Non</Label>
                  </AnswerCard>
                </CardsContainer>
              </Row2>
            </SmallContent>
          </StepContainer>
        </TreeRow>
      )}

    {!isAccessoryCreation
      && (
        <TreeRow>
          <StepContainer>
            <ProgressBarVertical
              isActive={isAlterationSelected}
              noLine
            />
            <SmallContent marginBottom="30px" mobileNoMarginTop mobileFullWidth>
              <Question center>Que souhaitez-vous réparer ?</Question>
              <Row2 noMarginBottomMobile>
                <CardsContainer mobileNoMarginTop mobileCenter marginTop={5} mobileFull>
                  {alterations.map((alteration, index) => (
                    <AnswerCard
                      key={alteration.label}
                      isActive={alteration.isActive}
                      onClick={() => setMultipleChoices(index, 'cushionAlterations')}
                    >
                      <Label>{alteration.label}</Label>
                    </AnswerCard>
                  ))}
                </CardsContainer>
              </Row2>
            </SmallContent>
          </StepContainer>
        </TreeRow>
      )}
  </div>
);

Step1Cushions.propTypes = {
  createRange: PropTypes.func.isRequired,
  setSelect: PropTypes.func.isRequired,
  setSingleChoice: PropTypes.func.isRequired,
  setMultipleChoices: PropTypes.func.isRequired,
  numberOfPieces: PropTypes.number,
  isSmall: PropTypes.bool,
  hasZip: PropTypes.bool,
  alterations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAlterationSelected: PropTypes.bool,
  isAccessoryCreation: PropTypes.bool,
};

Step1Cushions.defaultProps = {
  isSmall: undefined,
  hasZip: undefined,
  isAlterationSelected: false,
  numberOfPieces: undefined,
  isAccessoryCreation: false,
};

export default Step1Cushions;
