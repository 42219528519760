import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../styledComponents';

const ButtonContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0px 1px 6px ${colors.whiteTransparent};
  width: ${(props) => props.littleCard ? '224px' : '345px'};
  height: fit-content;
  margin: ${(props) => props.littleCard ? '0px 10px 53px 10px' : 'auto'};
  box-shadow: 0px 1px 6px ${colors.boxShadow};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  ${({ imageId }) => imageId && `
    &:hover #${imageId}{
      opacity: 0.7;
      transition: visibility .2s;
    }
  `}
  ${({ filterId }) => filterId && `
    &:hover #${filterId}{
      visibility: visible;
      transition: visibility .2s;
    }
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: ${(props) => props.littleCard ? 'calc(50vw - 30px)' : '100%'};
    margin: ${(props) => props.littleCard ? '0px 0 15px 0' : 'auto'};
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.littleCard ? '170px' : '210px'};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  ${({ showFilter }) => showFilter && 'opacity: 0.7;'}
`;

const TextContainer = styled.div`
  background-color:${(props) => props.active ? colors.navy : colors.white};
  height: ${(props) => props.littleCard ? '39px' : '50px'};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;

  @media (max-width: ${mobileThresholdPixels}) {
    height: ${(props) => props.littleCard ? '25px' : '25px'};
  }
`;

const TextButton = styled.div`
  font-family: Libre Baskerville;
  line-height: 25px;
  font-weight: 600;
  font-size: 15px;
  font-size: ${(prop) => prop.slug === 'bleu-jacquard-francais' ? '13px' : '15px'};
  color: ${(props) => props.active ? colors.white : colors.navy};
  text-align: center;
  transition: all 0.5s ease;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: ${(props) => props.littleCard ? '17px' : '12px'};
  }
`;

const Filter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: inherit;
  padding: 0px 22px;
  color: ${colors.white};
  box-sizing: border-box;
  text-align: center;
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 12px;
    ${({ isVisibleMobile }) => isVisibleMobile && `
      visibility: visible;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 10px;
      height: calc(100% - 25px);
      justify-content: center;
    `};
  }
`;

const FilterTitle = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const FilterText = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const CardSelector = ({
  slug, image, label, alt, onClick, active, littleCard, filterContent, isFurSelected,
}) => (
  <ButtonContainer
    slug={slug}
    onClick={() => onClick(slug)}
    littleCard={littleCard}
    imageId={filterContent?.showOnHover && `image${slug}`}
    filterId={filterContent?.showOnHover && `filter${slug}`}
  >
    <ImgContainer littleCard={littleCard}>
      <Img id={`image${slug}`} src={image} alt={alt} showFilter={filterContent && ((slug === 'fur' && isFurSelected) || active)} />
      {filterContent && (
        <Filter
          id={`filter${slug}`}
          isVisible={(slug === 'fur' && isFurSelected) || active}
          isVisibleMobile={filterContent.alwaysShowOnMobile}
        >
          <FilterTitle>{filterContent.title}</FilterTitle>
          <FilterText>{filterContent.text}</FilterText>
        </Filter>
      )}
    </ImgContainer>
    <TextContainer active={active} littleCard={littleCard}>
      <TextButton active={active} slug={slug}>
        {label}
      </TextButton>
    </TextContainer>
  </ButtonContainer>
);

CardSelector.propTypes = {
  slug: PropTypes.string.isRequired,
  image: PropTypes.string,
  label: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  littleCard: PropTypes.bool,
  filterContent: PropTypes.shape({
    showOnHover: PropTypes.bool,
    alwaysShowOnMobile: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  isFurSelected: PropTypes.bool,
};

CardSelector.defaultProps = {
  image: '',
  label: colors.navy,
  alt: 'Image Tilli',
  onClick() {},
  active: false,
  littleCard: false,
  filterContent: undefined,
  isFurSelected: false,
};

export default CardSelector;
