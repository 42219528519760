import React from 'react';
import PropTypes from 'prop-types';

import withAppContext from '../../withAppContext';
import { createRange } from './orderStyledComponents';

import CategorySelector from './CategorySelector';
import Step1Pieces from './Step1-1Pieces';
import Step1RDV3Clicks from './Step1-1RDV3Clicks';
import Step1Fabrics from './Step1-2Fabrics';
import Step1Tree from './Step1-3Tree';
import Step1AccessoryCreations from './Step1-4AccessoryCreations';
import Step1UpcyclingInspirations from './Step1-4UpcyclingInspirations';
import Step1Problems from './Step1-4Problems';
import Step1Linens from './Step1-4Linens';
import Step1Where from './Step1-5Where';
import Step1Multiple from './Step1-5Multiple';
import Step1Curtains from './Step1-2Curtains';
import Step1Cushions from './Step1-2Cushions';
import Step1Creations from './Step1-5Creations';
import SofaCreationsSelector from './SofaCreationsSelector';
import Step1Lining from './Step1-6Lining';
import Step1Embroideries from './Step1-2Embroideries';
import SuitSelector from './SuitSelector';
import WeListenToYouHeader from './WeListenToYouHeader';
import ComponentOrEmptyDiv from './ComponentOrEmptyDiv';
import treeUpcycling from './treeUpcycling';
import UpcyclingSelector from './UpcyclingSelector';

import {
  clothPieces, decoPieces, clothesCreations, decoCreations,
} from './step1/step1Data';
import { OpacityContainer, renderRelative, Container } from './step1FormComponents';
import ChairCreationsSelector from './ChairCreationsSelector';

const renderRDV3Clicks = ({ selectedCategory, selectCategory }) => (
  <ComponentOrEmptyDiv mustShow={selectedCategory === 'RDV3Clicks'}>
    <Step1RDV3Clicks unsetCategory={() => selectCategory('')} />
    <OpacityContainer />
  </ComponentOrEmptyDiv>
);

renderRDV3Clicks.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
};

const renderTypeOfWorkSelector = ({
  selectTypeOfWork, selectedCloth, selectedTypeOfWork,
}) => (
  <Step1Tree
    selectTypeOfWork={(slug) => selectTypeOfWork(slug)}
    selectedCloth={selectedCloth}
    selectedTypeOfWork={selectedTypeOfWork}
  />
);

renderTypeOfWorkSelector.propTypes = {
  selectTypeOfWork: PropTypes.func.isRequired,
  selectedCloth: PropTypes.string.isRequired,
  selectedTypeOfWork: PropTypes.string.isRequired,
};

const renderPieceSelector = ({
  selectedTypeOfWork, selectedCategory, selectCloth, selectPiece, selectedCloth, selectedPiece,
}) => {
  const isCloth = selectedCategory === 'clothes';
  return (
    <ComponentOrEmptyDiv mustShow={!!selectedTypeOfWork && selectedTypeOfWork !== 'creation'}>
      <Step1Pieces
        center={!isCloth}
        pieces={isCloth ? clothPieces : decoPieces}
        selectPiece={isCloth ? selectCloth : selectPiece}
        selectedPiece={isCloth ? selectedCloth : selectedPiece}
      />
    </ComponentOrEmptyDiv>
  );
};

renderPieceSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectCloth: PropTypes.func.isRequired,
  selectPiece: PropTypes.func.isRequired,
  selectedCloth: PropTypes.string.isRequired,
  selectedPiece: PropTypes.string.isRequired,
};

const renderAccessoryCreationSelector = ({
  selectedTypeOfWork, selectedCategory, selectAccessoryCreation, creationSlug,
}) => (
  <ComponentOrEmptyDiv mustShow={selectedTypeOfWork === 'creation'}>
    <Step1AccessoryCreations
      selectedCategory={selectedCategory}
      accessoryCreations={selectedCategory === 'deco' ? decoCreations : clothesCreations}
      selectAccessoryCreation={selectAccessoryCreation}
      creationSlug={creationSlug}
    />
  </ComponentOrEmptyDiv>
);

renderAccessoryCreationSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string.isRequired,
  selectAccessoryCreation: PropTypes.func.isRequired,
  creationSlug: PropTypes.string.isRequired,
};

const renderFabricSelector = ({
  selectedTypeOfWork, selectFabric, selectedFabric, selectedCloth, isFurSelected,
}) => {
  const mustShow = !!selectedTypeOfWork && selectedTypeOfWork !== 'creation'
    && !!selectedCloth && !['mari', 'pull', 'mail', 'ling', 'cost'].includes(selectedCloth);
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <Step1Fabrics
        selectFabric={(slug) => selectFabric(slug)}
        selectedFabric={selectedFabric}
        selectedCloth={selectedCloth}
        isFurSelected={isFurSelected}
      />
    </ComponentOrEmptyDiv>
  );
};

renderFabricSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string.isRequired,
  selectFabric: PropTypes.func.isRequired,
  selectedFabric: PropTypes.string.isRequired,
  selectedCloth: PropTypes.string.isRequired,
  isFurSelected: PropTypes.bool.isRequired,
};

const renderSuitSelector = ({
  selectedTypeOfWork, selectedCloth, suitPieces, toggleSuitPiece, suitProblems, toggleSuitProblem,
  suitItems, toggleSuitItem, setSuitItemQuantity, suitPieceLinings, selectSuitPieceLining,
  displaySummary, suitPiecesEmbroideries, setEmbroiderySingleChoiceForSuit, setEmbroideryMultipleChoicesForSuit,
  suitPiecesUpcycling, toggleSuitUpcyclingCategory, toggleSuitUpcyclingItem, setSuitUpcyclingItemQuantity,
}) => (
  <ComponentOrEmptyDiv mustShow={['alteration', 'embroidery', 'transformation'].includes(selectedTypeOfWork) && selectedCloth === 'cost'}>
    <WeListenToYouHeader />
    <SuitSelector
      suitPieces={suitPieces}
      toggleSuitPiece={toggleSuitPiece}
      suitProblems={suitProblems}
      toggleSuitProblem={toggleSuitProblem}
      suitItems={suitItems}
      toggleSuitItem={toggleSuitItem}
      setSuitItemQuantity={setSuitItemQuantity}
      createRange={createRange}
      suitPieceLinings={suitPieceLinings}
      selectSuitPieceLining={selectSuitPieceLining}
      typeOfWork={selectedTypeOfWork}
      displaySummary={displaySummary}
      suitPiecesEmbroideries={suitPiecesEmbroideries}
      setEmbroiderySingleChoiceForSuit={setEmbroiderySingleChoiceForSuit}
      setEmbroideryMultipleChoicesForSuit={setEmbroideryMultipleChoicesForSuit}
      suitPiecesUpcycling={suitPiecesUpcycling}
      toggleSuitUpcyclingCategory={toggleSuitUpcyclingCategory}
      toggleSuitUpcyclingItem={toggleSuitUpcyclingItem}
      setSuitUpcyclingItemQuantity={setSuitUpcyclingItemQuantity}
    />
  </ComponentOrEmptyDiv>
);

renderSuitSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string.isRequired,
  selectedCloth: PropTypes.string.isRequired,
  suitPieces: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSuitPiece: PropTypes.func.isRequired,
  suitProblems: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSuitProblem: PropTypes.func.isRequired,
  suitItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleSuitItem: PropTypes.func.isRequired,
  setSuitItemQuantity: PropTypes.func.isRequired,
  suitPieceLinings: PropTypes.shape({}).isRequired,
  selectSuitPieceLining: PropTypes.func.isRequired,
  displaySummary: PropTypes.bool.isRequired,
  suitPiecesEmbroideries: PropTypes.shape({}).isRequired,
  setEmbroiderySingleChoiceForSuit: PropTypes.func.isRequired,
  setEmbroideryMultipleChoicesForSuit: PropTypes.func.isRequired,
  suitPiecesUpcycling: PropTypes.shape({}).isRequired,
  toggleSuitUpcyclingCategory: PropTypes.func.isRequired,
  toggleSuitUpcyclingItem: PropTypes.func.isRequired,
  setSuitUpcyclingItemQuantity: PropTypes.func.isRequired,
};

const renderAlterationProblemsSelector = ({
  selectedTypeOfWork, selectedPiece, selectedFabric, selectedProblems,
  selectProblems, getTreeLocations, treeProblems, selectedCloth,
}) => {
  const mustShow = selectedTypeOfWork === 'alteration' && !selectedPiece && !!treeProblems
    && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling'].includes(selectedCloth));
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      {selectedCloth !== 'cost' && <WeListenToYouHeader />}
      <Step1Problems
        selectedProblems={selectedProblems}
        selectProblems={(slug) => selectProblems(slug, getTreeLocations(slug))}
        treeProblems={treeProblems}
        selectedCloth={selectedCloth}
      />
    </ComponentOrEmptyDiv>
  );
};

renderAlterationProblemsSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string.isRequired,
  selectProblems: PropTypes.func.isRequired,
  selectedFabric: PropTypes.string.isRequired,
  selectedPiece: PropTypes.string.isRequired,
  selectedCloth: PropTypes.string.isRequired,
  selectedProblems: PropTypes.shape({}).isRequired,
  getTreeLocations: PropTypes.func.isRequired,
  treeProblems: PropTypes.shape({}).isRequired,
};

const renderCurtainQuestionsSelector = (
  setSelect, setSingleChoice, setMultipleChoices,
  numberOfPieces, isCurtainLined, curtainAlterations, activeCurtainAdjustment,
) => (
  <div>
    <WeListenToYouHeader />
    <Step1Curtains
      createRange={createRange}
      setSelect={setSelect}
      setSingleChoice={setSingleChoice}
      setMultipleChoices={setMultipleChoices}
      numberOfPieces={numberOfPieces}
      isLined={isCurtainLined}
      alterations={curtainAlterations}
      activeAdjustment={activeCurtainAdjustment}
    />
  </div>
);

const renderCushionQuestionsSelector = (
  setSelect, setSingleChoice, setMultipleChoices, numberOfPieces,
  isCushionSmall, cushionHasZip, cushionAlterations, isCushionAlterationSelected,
) => (
  <div>
    <WeListenToYouHeader />
    <Step1Cushions
      createRange={createRange}
      setSelect={setSelect}
      setSingleChoice={setSingleChoice}
      setMultipleChoices={setMultipleChoices}
      numberOfPieces={numberOfPieces}
      isSmall={isCushionSmall}
      hasZip={cushionHasZip}
      alterations={cushionAlterations}
      isAlterationSelected={isCushionAlterationSelected}
    />
  </div>
);

const renderLinenQuestionsSelector = (
  setSelect, setSingleChoice, setMultipleChoices,
  linensProblems, linensAlterations, linensSizes, linensWhere,
) => (
  <div>
    <WeListenToYouHeader />
    <Step1Linens
      createRange={createRange}
      setSelect={setSelect}
      setSingleChoice={setSingleChoice}
      setMultipleChoices={setMultipleChoices}
      problems={linensProblems}
      alterations={linensAlterations}
      sizes={linensSizes}
      where={linensWhere}
    />
  </div>
);

const renderDecoQuestionsContainer = ({
  selectedTypeOfWork, selectedPiece, setSelect, setSingleChoice, setMultipleChoices,
  numberOfPieces, isCurtainLined, curtainAlterations, activeCurtainAdjustment,
  isCushionSmall, cushionHasZip, cushionAlterations, isCushionAlterationSelected,
  linensProblems, linensAlterations, linensSizes, linensWhere,
}) => {
  if (selectedTypeOfWork === 'alteration' && selectedPiece === 'ride') {
    return (renderCurtainQuestionsSelector(
      setSelect, setSingleChoice, setMultipleChoices,
      numberOfPieces, isCurtainLined, curtainAlterations, activeCurtainAdjustment,
    ));
  }
  if (selectedTypeOfWork === 'alteration' && selectedPiece === 'cous') {
    return (renderCushionQuestionsSelector(
      setSelect, setSingleChoice, setMultipleChoices, numberOfPieces,
      isCushionSmall, cushionHasZip, cushionAlterations, isCushionAlterationSelected,
    ));
  }
  if (selectedTypeOfWork === 'alteration' && selectedPiece === 'mais') {
    return (renderLinenQuestionsSelector(
      setSelect, setSingleChoice, setMultipleChoices,
      linensProblems, linensAlterations, linensSizes, linensWhere,
    ));
  }
  return <div />;
};

renderDecoQuestionsContainer.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedPiece: PropTypes.string,
  setSelect: PropTypes.func,
  setSingleChoice: PropTypes.func,
  setMultipleChoices: PropTypes.func,
  numberOfPieces: PropTypes.number,
  isCurtainLined: PropTypes.bool,
  curtainAlterations: PropTypes.arrayOf(PropTypes.shape({})),
  activeCurtainAdjustment: PropTypes.number,
  isCushionSmall: PropTypes.bool,
  cushionHasZip: PropTypes.bool,
  isCushionAlterationSelected: PropTypes.bool,
  cushionAlterations: PropTypes.arrayOf(PropTypes.shape({})),
  linensProblems: PropTypes.arrayOf(PropTypes.shape({})),
  linensAlterations: PropTypes.arrayOf(PropTypes.shape({})),
  linensSizes: PropTypes.arrayOf(PropTypes.shape({})),
  linensWhere: PropTypes.arrayOf(PropTypes.shape({})),
};

const renderEmbroideriesSelector = ({
  selectedTypeOfWork, selectedPiece, selectedCloth, selectedFabric, setSingleChoice, setMultipleChoices,
  embroideries, activeEmbroideryFontSize, activeEmbroideryDrawingSize,
  isDrawingEasy, isDrawingFull, displaySummary,
}) => {
  const mustShow = selectedTypeOfWork === 'embroidery' && (!!selectedPiece || (!!selectedCloth && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling'].includes(selectedCloth))));
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <WeListenToYouHeader />
      <Step1Embroideries
        pieceSlug={selectedCloth}
        setSingleChoice={setSingleChoice}
        setMultipleChoices={setMultipleChoices}
        embroideries={embroideries}
        activeEmbroideryFontSize={activeEmbroideryFontSize}
        activeEmbroideryDrawingSize={activeEmbroideryDrawingSize}
        isDrawingFull={isDrawingFull}
        isDrawingEasy={isDrawingEasy}
        displaySummary={displaySummary}
      />
    </ComponentOrEmptyDiv>
  );
};

renderEmbroideriesSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedPiece: PropTypes.string,
  selectedCloth: PropTypes.string,
  selectedFabric: PropTypes.string,
  setSingleChoice: PropTypes.func,
  setMultipleChoices: PropTypes.func,
  embroideries: PropTypes.arrayOf(PropTypes.shape({})),
  activeEmbroideryFontSize: PropTypes.number,
  activeEmbroideryDrawingSize: PropTypes.number,
  isDrawingFull: PropTypes.bool,
  isDrawingEasy: PropTypes.bool,
  displaySummary: PropTypes.bool,
};

const renderUpcyclingInspirationsContainer = ({
  selectedTypeOfWork, selectedCloth, selectedFabric,
}) => {
  const mustShow = selectedTypeOfWork === 'transformation' && !!selectedCloth && selectedCloth !== 'cost';
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <Step1UpcyclingInspirations
        selectedCloth={selectedCloth}
        selectedFabric={selectedFabric}
      />
    </ComponentOrEmptyDiv>
  );
};

renderUpcyclingInspirationsContainer.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedCloth: PropTypes.string,
  selectedFabric: PropTypes.string,
};

const renderAccessoryCreationQuestionsSelector = ({
  creationSlug, selectedCreationNumberOfPieces, creationShape, creationSize, creationHeight,
  creationFirstOption, creationSecondOption, selectCreationOptions, selectAccessoryCreation,
  toggleSofaPart, sofaParts, toggleSofaPartSubType, sofaPartsSubTypes, toggleSofaPartItem, sofaPartsItems,
  sofaPartsNumber, setSofaPartNumber,
}) => (
  <ComponentOrEmptyDiv mustShow={creationSlug?.length > 0}>
    <WeListenToYouHeader />
    {creationSlug === 'crea_sofa'
      && (
        <SofaCreationsSelector
          sofaParts={sofaParts}
          toggleSofaPart={toggleSofaPart}
          sofaPartsSubTypes={sofaPartsSubTypes}
          toggleSofaPartSubType={toggleSofaPartSubType}
          sofaPartsItems={sofaPartsItems}
          toggleSofaPartItem={toggleSofaPartItem}
          sofaPartsNumber={sofaPartsNumber}
          setSofaPartNumber={setSofaPartNumber}
          createRange={createRange}
        />
      )}
    {['crea_chai', 'crea_stoo'].includes(creationSlug)
      && (
        <ChairCreationsSelector
          changeSlug={selectAccessoryCreation}
          selectedCreationNumberOfPieces={selectedCreationNumberOfPieces}
          creationSlug={creationSlug}
          creationSize={creationSize}
          creationFirstOption={creationFirstOption}
          creationSecondOption={creationSecondOption}
          selectCreationOptions={selectCreationOptions}
          createRange={createRange}
        />
      )}
    {!['crea_chai', 'crea_stoo', 'crea_sofa'].includes(creationSlug)
      && (
        <Step1Creations
          selectedCreationNumberOfPieces={selectedCreationNumberOfPieces}
          creationSlug={creationSlug}
          creationShape={creationShape}
          creationSize={creationSize}
          creationHeight={creationHeight}
          creationFirstOption={creationFirstOption}
          creationSecondOption={creationSecondOption}
          selectCreationOptions={selectCreationOptions}
          createRange={createRange}
        />
      )}
  </ComponentOrEmptyDiv>
);

renderAccessoryCreationQuestionsSelector.propTypes = {
  creationSlug: PropTypes.string,
  creationShape: PropTypes.shape({}),
  selectedCreationNumberOfPieces: PropTypes.number,
  creationSize: PropTypes.shape({}),
  creationHeight: PropTypes.shape({}),
  creationFirstOption: PropTypes.shape({}),
  creationSecondOption: PropTypes.shape({}),
  selectCreationOptions: PropTypes.func,
  toggleSofaPart: PropTypes.func,
  sofaParts: PropTypes.arrayOf(PropTypes.string),
  toggleSofaPartSubType: PropTypes.func,
  sofaPartsSubTypes: PropTypes.shape({}),
  toggleSofaPartItem: PropTypes.func,
  sofaPartsItems: PropTypes.shape({}),
  sofaPartsNumber: PropTypes.shape({}),
  setSofaPartNumber: PropTypes.func,
};

const renderProblemsAnswersSelector = ({
  selectedProblems, getTreeLocations, selectLocations,
}) => (
  <ComponentOrEmptyDiv mustShow={!!selectedProblems && Object.keys(selectedProblems).length > 0}>
    {Object.keys(selectedProblems)
      .filter((selectedProblem) => getTreeLocations(selectedProblem)?.locations?.length > 1)
      .filter((selectedProblem) => selectedProblem !== 'talkRDV' && !selectedProblem.includes('_multiple'))
      .map((selectedProblem) => (
        <Step1Where
          key={selectedProblem}
          selectedLocations={selectedProblems[selectedProblem]}
          selectLocations={(slug) => selectLocations(selectedProblem, slug)}
          treeLocations={getTreeLocations(selectedProblem)}
        />
      ))}
  </ComponentOrEmptyDiv>
);

renderProblemsAnswersSelector.propTypes = {
  selectedProblems: PropTypes.shape({}),
  getTreeLocations: PropTypes.func,
  selectLocations: PropTypes.func,
};

const renderProblemsAnswersMultiSelector = ({
  selectedProblems, setSelectValue, selectedCloth,
}) => (
  <ComponentOrEmptyDiv mustShow={!!selectedProblems && Object.keys(selectedProblems).length > 0}>
    {Object.keys(selectedProblems)
      .filter((selectedProblem) => selectedProblem.includes('_multiple') && selectedProblems[selectedProblem]?.length > 0)
      .map((selectedProblem, index, filteredSelectedProblems) => (
        <Step1Multiple
          key={selectedProblem}
          slug={selectedProblem}
          createRange={createRange}
          setSelectValue={setSelectValue}
          selectedValue={selectedProblems[selectedProblem][0]}
          isLast={selectedCloth === 'acce' && index === filteredSelectedProblems.length - 1}
        />
      ))}
  </ComponentOrEmptyDiv>
);

renderProblemsAnswersMultiSelector.propTypes = {
  selectedProblems: PropTypes.shape({}),
  setSelectValue: PropTypes.func,
  selectedCloth: PropTypes.string,
};

const renderUpcyclingItemsSelector = ({
  selectedTypeOfWork, selectedPiece, selectedCloth, selectedFabric, upcyclingCategories,
  toggleUpcyclingCategory, upcyclingItems, toggleUpcyclingItem, setUpcyclingItemQuantity,
}) => {
  const mustShow = selectedTypeOfWork === 'transformation' && !selectedPiece
    && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling', 'cost'].includes(selectedCloth))
    && !!treeUpcycling[selectedCloth];
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <WeListenToYouHeader />
      <UpcyclingSelector
        tree={treeUpcycling[selectedCloth]}
        upcyclingCategories={upcyclingCategories}
        toggleUpcyclingCategory={toggleUpcyclingCategory}
        upcyclingItems={upcyclingItems}
        toggleUpcyclingItem={toggleUpcyclingItem}
        createRange={createRange}
        setUpcyclingItemQuantity={setUpcyclingItemQuantity}
      />
    </ComponentOrEmptyDiv>
  );
};

renderUpcyclingItemsSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedPiece: PropTypes.string,
  selectedCloth: PropTypes.string,
  selectedFabric: PropTypes.string,
  upcyclingCategories: PropTypes.arrayOf(PropTypes.string),
  toggleUpcyclingCategory: PropTypes.func,
  upcyclingItems: PropTypes.arrayOf(PropTypes.shape({})),
  toggleUpcyclingItem: PropTypes.func,
  setUpcyclingItemQuantity: PropTypes.func,
};

const renderLiningSelector = ({
  isLiningDisplayed, selectLining, selectedLining,
}) => (
  <ComponentOrEmptyDiv mustShow={isLiningDisplayed}>
    <Step1Lining
      selectLining={selectLining}
      selectedLining={selectedLining}
    />
  </ComponentOrEmptyDiv>
);

renderLiningSelector.propTypes = {
  isLiningDisplayed: PropTypes.bool,
  selectLining: PropTypes.func,
  selectedLining: PropTypes.string,
};

const renderStandardSteps = (props) => {
  const { setRef, context: { isMobile } } = props;

  return (
    <div>
      {renderRelative(setRef, 'Step1TypeOfWork')}

      {renderTypeOfWorkSelector(props)}

      {renderRelative(setRef, 'Step1Piece', !isMobile)}

      {renderPieceSelector(props)}
      {renderAccessoryCreationSelector(props)}

      {renderRelative(setRef, 'Step1Fabrics', !isMobile)}

      {renderFabricSelector(props)}

      {renderRelative(setRef, 'Step1AdditionalInformation', !isMobile)}

      {renderSuitSelector(props)}
      {renderAlterationProblemsSelector(props)}
      {renderProblemsAnswersSelector(props)}
      {renderProblemsAnswersMultiSelector(props)}
      {renderLiningSelector(props)}

      {renderDecoQuestionsContainer(props)}
      {renderEmbroideriesSelector(props)}
      {renderUpcyclingInspirationsContainer(props)}
      {renderAccessoryCreationQuestionsSelector(props)}
      {renderUpcyclingItemsSelector(props)}
    </div>
  );
};

renderStandardSteps.propTypes = {
  setRef: PropTypes.func.isRequired,
  displaySummary: PropTypes.bool,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

const Step1Form = (props) => {
  const { setRef, selectedCategory, selectCategory } = props;

  return (
    <>
      <Container marginBottom={selectedCategory === 'RDV3Clicks'}>
        <CategorySelector category={selectedCategory} selectCategory={selectCategory} />

        {renderRelative(setRef, 'Step1Top')}

        {renderRDV3Clicks(props)}

        {!!selectedCategory && selectedCategory !== 'RDV3Clicks'
          ? renderStandardSteps(props) : <div />}
      </Container>
    </>
  );
};

Step1Form.propTypes = {
  selectedCategory: PropTypes.string,
  setRef: PropTypes.func.isRequired,
  selectCategory: PropTypes.func.isRequired,
};

Step1Form.defaultProps = {
  selectedCategory: undefined,
};

export default withAppContext(Step1Form);
