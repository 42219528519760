import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import { treeAlterationSuit } from './tree';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const SuitProblemsSelector = ({
  suitPiece, suitProblems, toggleSuitProblem, isLast,
}) => {
  const suitProblemsForCurrentPiece = suitProblems.filter((suitProblem) => suitProblem.includes(`${suitPiece}_`));
  return (
    <TreeRow>
      <StepContainer>
        <ProgressBarVertical
          isActive={suitProblemsForCurrentPiece.length > 0}
          height={200}
          noLine={isLast && (suitProblemsForCurrentPiece.length === 0 || (suitProblemsForCurrentPiece.length === 1 && suitProblemsForCurrentPiece[0].includes('_talkRDV')))}
        />
        <SmallContent flexStart marginLeft>
          <Question>
            {treeAlterationSuit.pieces[suitPiece].question}
          </Question>
          <Row2>
            <CardsContainer>
              {Object.entries(treeAlterationSuit.pieces[suitPiece].problems).map(([slug, { label }]) => (
                <AnswerCard
                  key={slug}
                  onClick={() => toggleSuitProblem(suitPiece, `${suitPiece}_${slug}`)}
                  isActive={suitProblems.includes(`${suitPiece}_${slug}`)}
                >
                  <Label>
                    {label}
                  </Label>
                </AnswerCard>
              ))}
            </CardsContainer>
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>
  );
};

SuitProblemsSelector.propTypes = {
  suitPiece: PropTypes.string.isRequired,
  suitProblems: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSuitProblem: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default SuitProblemsSelector;
