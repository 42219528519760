import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../layouts/index';
import { Container, Content, StepContainer } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Step1Component from '../../components/Step1';
import ProgressBar from '../../components/order/ProgressBar';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';
import { getInitialStateProp } from '../../components/order/preFilledClothes';

const Step1 = ({ location }) => (
  <Layout routeSlug="Step1">
    <Container>
      <MenuBar showGiftBanner={false} />
      <Content hasNoBanner>
        <StepContainer>
          <ProgressBar step={1} />
          <Step1Component initialState={getInitialStateProp(location)} />
        </StepContainer>
      </Content>
      <BenefitsBanner backgroundColor="linen" color="navy" />
      <Footer />
    </Container>
  </Layout>
);

Step1.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedCategory: PropTypes.string,
      selectedPiece: PropTypes.string,
      selectedCloth: PropTypes.string,
      selectedFabric: PropTypes.string,
      selectedTypeOfWork: PropTypes.string,
    }),
  }).isRequired,
};

export default Step1;
