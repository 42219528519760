import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Row, Subtitle, Content, CardsContainer as CardsContainerOrder,
} from './orderStyledComponents';
import Dots from '../home/v3/Dots';
import CardSelector from './CardSelector';
import { mobileThresholdPixels } from '../home/v3/styledComponents';

const CardsContainer = styled(CardsContainerOrder)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
    width: 100%;
    justify-content: space-between;
  }
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Step1Pieces = ({
  center, pieces, selectPiece, selectedPiece,
}) => (
  <Row smallMarginTop>
    <Content marginTop={100}>
      <Subtitle>De quelle pièce s’agit-il ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <Row2>
        <CardsContainer center={center}>
          {pieces.map((piece) => (
            <CardSelector
              key={piece.slug}
              slug={piece.slug}
              image={piece.image}
              label={piece.name}
              alt={`Tilli retouche votre ${piece.name}`}
              onClick={() => selectPiece(piece.slug)}
              littleCard
              active={selectedPiece === piece.slug}
            />
          ))}
        </CardsContainer>
      </Row2>
    </Content>
  </Row>
);

Step1Pieces.propTypes = {
  center: PropTypes.bool,
  pieces: PropTypes.arrayOf(PropTypes.shape({})),
  selectPiece: PropTypes.func,
  selectedPiece: PropTypes.string,
};

Step1Pieces.defaultProps = {
  center: false,
  pieces: [],
  selectPiece() { },
  selectedPiece: '',
};

export default Step1Pieces;
