import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import fabrics from './fabrics';
import withAppContext from '../../withAppContext';
import withOrderContext from '../../withOrderContext';
import routesMap from '../../Routes';

import OutInSideClick from '../home/v2/OutInSideClick';
import { pushToLayer } from '../../services/googleTagManager';
import Dots from '../home/v3/Dots';

import topSuit from '../../assets/order/rdv3clics/top-suit.png';
import bottomSuit from '../../assets/order/rdv3clics/bottom-suit.png';
import {
  Title, Subtitle, CardsContainer, KnowHowCard, FabricCard, FabricImgContainer, FabricImg, Filter, FilterText,
  FilterTitle, FabricName, SuitAlterationsRow, SuitAlterationsWrapperImg, SuitAlterationsImg, SuitAlterationsWrapperButton,
  SuitAlterationsButton, Button, Container, Text,
} from './step1-1RDV3ClicksComponents';

const getKnowHowList = () => (
  [
    { label: 'Ce sont mes rideaux', isActive: false, slug: 'curtain' },
    { label: 'C’est un costume', isActive: false, slug: 'suit' },
    { label: 'C’est de la broderie', isActive: false, slug: 'embroidery' },
    { label: 'C’est ma robe de mariée', isActive: false, slug: 'mari' },
    { label: 'C’est de la lingerie', isActive: false, slug: 'lingerie' },
    { label: 'C’est une création d’accessoires', isActive: false, slug: 'creation' },
    { label: 'C’est un vêtement technique', isActive: false, slug: 'bath' },
    { label: 'C’est un manteau/veste', isActive: false, slug: 'coat' },
    { label: 'Transformer le design d’un vêtement', isActive: false, slug: 'upcycling' },
    { label: 'Rien de spécial', isActive: false, slug: '' },
  ]
);

const getAlterationsSuitList = () => (
  [
    {
      label: 'Le col',
      isActive: false,
      slug: 'collar',
    },
    {
      label: 'Les manches',
      isActive: false,
      slug: 'sleeves',
    },
    {
      label: 'La ceinture',
      isActive: false,
      slug: 'belt',
    },
    {
      label: 'Les jambes',
      isActive: false,
      slug: 'legs',
    },
    {
      label: 'Rien de compliqué (ourlet, réparation …)',
      isActive: false,
      slug: 'nothingComplex',
    },
  ]
);

const getFabricsList = () => (
  fabrics
    .filter((fabric) => fabric.name !== 'Tailleur')
    .map((fabric) => ({ ...fabric, isActive: false }))
);

const renderHeader = () => (
  <>
    <Title marginBottom={40}>Quelques détails pour vous envoyer le meilleur couturier</Title>
    <Text>Certaines matières nécessitent un savoir-faire et des outils spécifiques.</Text>
    <Text>Si besoin, nous vous enverrons un spécialiste. Pas d’inquiétude, le prix reste le même.</Text>
  </>
);

function getInitialState() {
  return {
    alterationsActivitiesSuit: getAlterationsSuitList(),
    fabricsActivities: getFabricsList(),
    knowHowActivities: getKnowHowList(),
    isFurSelected: false,
    nbMasks: 0,
  };
}

class Step1RDV3Clicks extends React.Component {
  constructor(props) {
    super(props);
    const { orderContext: { rdv3ClicksOrder } } = this.props;

    if ((rdv3ClicksOrder?.knowHow || []).length > 0) {
      const rdv3ClicksKnowHowSlugs = rdv3ClicksOrder.knowHow.map((knowHow) => knowHow.slug);
      const knowHowActivities = getKnowHowList()
        .map((knowHow) => ({ ...knowHow, isActive: rdv3ClicksKnowHowSlugs?.includes(knowHow.slug) }));

      const fabricsActivities = getFabricsList()
        .map((fabric) => ({ ...fabric, isActive: rdv3ClicksOrder?.fabrics?.includes(fabric.slug) }));

      const rdv3ClicksSuitAlterationsSlugs = rdv3ClicksOrder.suitAlterations?.map((alteration) => alteration.slug);
      const alterationsActivitiesSuit = getAlterationsSuitList()
        .map((alteration) => ({ ...alteration, isActive: rdv3ClicksSuitAlterationsSlugs?.includes(alteration.slug) }));
      const nbMasks = rdv3ClicksOrder.nbMasks || 0;

      this.state = {
        alterationsActivitiesSuit,
        fabricsActivities,
        knowHowActivities,
        isFurSelected: rdv3ClicksOrder.isFurSelected,
        nbMasks,
      };
    } else {
      this.state = getInitialState();
    }
  }

  getIsSelectedSuitCloth = () => {
    const { knowHowActivities } = this.state;
    return knowHowActivities.find((item) => item.slug === 'suit').isActive;
  }

  getNumberOfActiveKnowhow = () => {
    const { knowHowActivities } = this.state;
    return knowHowActivities.filter((item) => item.isActive === true).length;
  }

  goNext = () => {
    const {
      knowHowActivities, fabricsActivities, alterationsActivitiesSuit, nbMasks, isFurSelected,
    } = this.state;
    const { orderContext: { setRDV3ClicksOrder } } = this.props;

    const selectedKnowHow = knowHowActivities
      .filter((knowHow) => knowHow.isActive)
      .map(({ label, slug }) => ({ label, slug }));

    const selectedFabrics = fabricsActivities.filter((fabric) => fabric.isActive).map((fabric) => fabric.slug);

    const selectedAlterationsSuit = alterationsActivitiesSuit
      .filter((alteration) => alteration.isActive)
      .map(({ label, slug }) => ({ label, slug }));

    setRDV3ClicksOrder(selectedKnowHow, selectedFabrics, selectedAlterationsSuit, nbMasks, isFurSelected);
    navigate(routesMap.Step2.url);
  }

  toggleCardActivity = (index, listName) => {
    const { state } = this;
    const { fabricsActivities } = state;
    let list = state[listName];

    if (listName === 'fabricsActivities' && list[index].slug === 'fur') {
      this.setState({ isFurSelected: true });
    } else {
      if (list[index].slug === '' && !list[index].isActive) {
        list = list.map((item) => ({ ...item, isActive: item.slug === '' }));
      } else {
        list[index].isActive = !list[index].isActive;
        if (list[index].slug !== '' && list[index].isActive) {
          list = list.map((item) => ({ ...item, isActive: item.slug === '' ? false : item.isActive }));
        }
      }
      this.setState({ [listName]: [...list] });
    }
    if (listName === 'knowHowActivities') {
      pushToLayer({
        event: 'Order Funnel - Lite - Know-How Chosen',
      });
    } else {
      pushToLayer({
        event: 'Order Funnel - Lite - Fabric Chosen',
        fabric: fabricsActivities[index].slug,
      });
    }
  }

  toggleSuit = (slug, index) => {
    const { alterationsActivitiesSuit } = this.state;
    let newAlterationsActivitiesSuit = alterationsActivitiesSuit.slice();
    const nothingComplexIndex = newAlterationsActivitiesSuit.findIndex((item) => item.slug === 'nothingComplex');

    if (slug === 'nothingComplex' && newAlterationsActivitiesSuit[nothingComplexIndex].isActive) {
      newAlterationsActivitiesSuit[nothingComplexIndex].isActive = false;
    } else if (slug === 'nothingComplex' && !newAlterationsActivitiesSuit[nothingComplexIndex].isActive) {
      newAlterationsActivitiesSuit = getAlterationsSuitList();
      newAlterationsActivitiesSuit[nothingComplexIndex].isActive = true;
    } else {
      newAlterationsActivitiesSuit[index].isActive = !newAlterationsActivitiesSuit[index].isActive;
      newAlterationsActivitiesSuit[nothingComplexIndex].isActive = false;
    }

    this.setState({
      alterationsActivitiesSuit: [...newAlterationsActivitiesSuit],
    });
  }

  renderKnowHowActivities = (knowHowActivities) => (
    <>
      <Subtitle marginTop>Besoin d’un savoir-faire spécifique ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <CardsContainer mobileCenter>
        {knowHowActivities.map((knowHow, index) => (
          <KnowHowCard
            key={knowHow.label}
            onClick={() => this.toggleCardActivity(index, 'knowHowActivities')}
            isActive={knowHow.isActive}
          >
            {knowHow.label}
          </KnowHowCard>
        ))}
        <KnowHowCard unavailable />
      </CardsContainer>
    </>
  );

  renderFabricsActivities = (fabricsActivities, isMobile, isFurSelected) => (
    <>
      <Subtitle>Une matière particulière ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <CardsContainer>
        {fabricsActivities.map(({
          slug, name, isActive, image, filterContent,
        }, index) => (
          <FabricCard
            key={name}
            onClick={() => this.toggleCardActivity(index, 'fabricsActivities')}
            imageId={filterContent?.showOnHover && !isMobile && `image${slug}`}
            filterId={filterContent?.showOnHover && !isMobile && `filter${slug}`}
          >
            <FabricImgContainer>
              <FabricImg id={`image${slug}`} src={image} alt={name} showFilter={filterContent && ((slug === 'fur' && isFurSelected) || isActive)} />
              {filterContent && (
                <Filter
                  id={`filter${slug}`}
                  isVisible={(slug === 'fur' && isFurSelected) || isActive}
                  isVisibleMobile={filterContent.alwaysShowOnMobile}
                >
                  <FilterTitle>{filterContent.title}</FilterTitle>
                  <FilterText>{filterContent.text}</FilterText>
                </Filter>
              )}
            </FabricImgContainer>
            <FabricName isActive={isActive}>
              {(isMobile && slug === 'other') ? 'Autre' : name}
            </FabricName>
          </FabricCard>
        ))}
      </CardsContainer>
    </>
  );

  renderAlterationsActivitiesSuit = (alterationsActivitiesSuit) => (
    <>
      <Subtitle>À quel endroit seront les retouches ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <SuitAlterationsRow mobileCenter firstChild>
        <SuitAlterationsWrapperImg>
          <SuitAlterationsImg src={topSuit} alt="Retouche" topSuit />
        </SuitAlterationsWrapperImg>
        <SuitAlterationsWrapperButton>
          {alterationsActivitiesSuit.slice(0, 2).map(({ label, isActive, slug }, index) => (
            <SuitAlterationsButton
              key={label}
              onClick={() => this.toggleSuit(slug, index)}
              isActive={isActive}
            >
              {label}
            </SuitAlterationsButton>
          ))}
        </SuitAlterationsWrapperButton>
      </SuitAlterationsRow>
      <SuitAlterationsRow mobileCenter>
        <SuitAlterationsWrapperImg bottomSuit>
          <SuitAlterationsImg src={bottomSuit} alt="Retouche" />
        </SuitAlterationsWrapperImg>
        <SuitAlterationsWrapperButton>
          {alterationsActivitiesSuit.slice(2, 4).map(({ label, isActive, slug }, index) => (
            <SuitAlterationsButton
              key={label}
              onClick={() => this.toggleSuit(slug, `${index + 2}`)}
              isActive={isActive}
            >
              {label}
            </SuitAlterationsButton>
          ))}
        </SuitAlterationsWrapperButton>
      </SuitAlterationsRow>
      <SuitAlterationsRow mobileCenter largeButton>
        {alterationsActivitiesSuit
          .filter((item, index) => alterationsActivitiesSuit.length - 1 === index)
          .map(({ label, isActive, slug }, index) => (
            <SuitAlterationsButton
              largeButton
              key={label}
              onClick={() => this.toggleSuit(slug, index)}
              isActive={isActive}
            >
              {label}
            </SuitAlterationsButton>
          ))}
      </SuitAlterationsRow>
    </>
  );

  render() {
    const { context: { isMobile }, unsetCategory } = this.props;
    const {
      knowHowActivities, fabricsActivities, alterationsActivitiesSuit, isFurSelected,
    } = this.state;
    const isSelectedSuitCloth = this.getIsSelectedSuitCloth();
    const numberOfActiveKnowhow = this.getNumberOfActiveKnowhow();
    const isDone = knowHowActivities.reduce((acc, { isActive }) => acc || isActive, false);

    return (
      <OutInSideClick handleClickOutside={unsetCategory}>
        <Container>
          {renderHeader()}

          {this.renderKnowHowActivities(knowHowActivities)}

          {isSelectedSuitCloth && numberOfActiveKnowhow === 1
            && this.renderAlterationsActivitiesSuit(alterationsActivitiesSuit)}

          {isSelectedSuitCloth && numberOfActiveKnowhow > 1
            && (
              <>
                {this.renderAlterationsActivitiesSuit(alterationsActivitiesSuit)}
                {this.renderFabricsActivities(fabricsActivities, isMobile, isFurSelected)}
              </>
            )}

          {!isSelectedSuitCloth && numberOfActiveKnowhow >= 1
            && this.renderFabricsActivities(fabricsActivities, isMobile, isFurSelected)}

          <Button navy onClick={this.goNext} isAvailable={isDone}>Prendre RDV</Button>
        </Container>
      </OutInSideClick>
    );
  }
}

Step1RDV3Clicks.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  orderContext: PropTypes.shape({
    rdv3ClicksOrder: PropTypes.shape({
      knowHow: PropTypes.arrayOf(PropTypes.shape({})),
      fabrics: PropTypes.arrayOf(PropTypes.string),
      suitAlterations: PropTypes.arrayOf(PropTypes.shape({})),
      nbMasks: PropTypes.number,
      isFurSelected: PropTypes.bool,
    }),
    setRDV3ClicksOrder: PropTypes.func,
  }).isRequired,
  unsetCategory: PropTypes.func.isRequired,
};

export default withAppContext(withOrderContext(Step1RDV3Clicks));
