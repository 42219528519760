import React from 'react';
import styled from 'styled-components';

import { mobileThresholdPixels } from '../styledComponents';
import { colors as colorsV3 } from '../home/v3/styledComponents';

const Relative = styled.div`
  position: relative;
`;

const ScrollingPosition = styled.div`
  position: absolute;
  top: ${(props) => props.lower ? '-103px' : '-43px'};
`;

export const OpacityContainer = styled.div`
  background: ${colorsV3.opacityFilter};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

export const Container = styled.div`
  width: 100%;
  ${(props) => props.marginBottom && `
    margin-bottom: -700px;
    @media (max-width: ${mobileThresholdPixels}) {
      margin-bottom: -850px;
    }
  `}
`;

export const renderRelative = (setRef, key, lower = false) => (
  <Relative>
    <ScrollingPosition lower={lower} ref={(component) => setRef(key, component)} />
  </Relative>
);
