import styled from 'styled-components';

import { Title as TitleOrder, Subtitle as SubtitleOrder } from './orderStyledComponents';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';

export const Container = styled.div`
  background-color: ${colors.white};
  color: ${colors.navy};
  font-family: Roboto;
  width: 994px;
  position: relative;
  left: 0;
  right: 0;
  top: -700px;
  margin: 0 auto;
  padding: 88px 142px 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 10px);
    padding: 88px 20px 60px 20px;
    top: -850px;
  }
`;

export const Title = styled(TitleOrder)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 28px;
  }
`;

export const Subtitle = styled(SubtitleOrder)`
  ${(props) => props.yellow && `color: ${colors.yellow};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const Text = styled.p`
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 15px;
    font-size: 12px;
    padding: 0px 10px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 70px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
    ${(props) => props.mobileCenter && 'justify-content: center; margin-bottom: 40px;'}
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.isActive && `background-color: ${colors.navy}; color: ${colors.white};`}
`;

export const KnowHowCard = styled(FlexContainer)`
  width: 200px;
  height: 40px;
  line-height: 1;
  margin: 10px 0px;
  padding: 0 5px;
  text-align: center;
  width: 200px;
  ${(props) => !props.unavailable && `
    border: 0.75px solid ${colors.blueGrey};
    border-radius: 3px;
    box-sizing: border-box;
    margin: 5px 0px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

export const FabricCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
  margin-bottom: 51px;
  box-shadow: 0px 1px 6px ${colors.blueGrey};
  cursor: pointer;
  ${({ imageId }) => imageId && `
    &:hover #${imageId}{
      opacity: 0.7;
      transition: visibility .2s;
    }
  `}
  ${({ filterId }) => filterId && `
    &:hover #${filterId}{
      visibility: visible;
      transition: visibility .2s;
    }
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
    width: calc(50vw - 50px);
  }
`;

export const FabricImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    width: 100%;
  }
`;

export const FabricImg = styled.img`
  height: 100%;
  width: 100%;
  ${({ showFilter }) => showFilter && 'opacity: 0.7;'}
`;

export const FabricName = styled(FlexContainer)`
  height: 39px;
  font-family: Libre Baskerville;
  @media (max-width: ${mobileThresholdPixels}) {
    height: 25px;
  }
`;

export const Button = styled(ButtonV3)`
  width: 245px;
  margin-top: -41px;
  ${(props) => !props.isAvailable && `
    background-color: ${colors.lightGrey3};
    color: ${colors.white};
    pointer-events: none;
    cursor: unset;
    border-color: ${colors.lightGrey3};
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
    height: 45px;
    width: 200.5px;
    margin-top: 60px;
  }
`;

export const SuitAlterationsRow = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  line-height: 26px;
  margin-top: 40px;
  width: 100%;
  ${(props) => props.largeButton && `
    padding-bottom: 110px;
    padding-top: 20px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 0;
    width: 274px;
    ${(props) => props.mobileCenter && 'justify-content: center;'}
    ${(props) => props.firstChild && 'margin-top: 40px;'}
    ${(props) => props.largeButton && `
      padding-bottom: 60px;
      padding-top: 0;
  `}
  }
`;

export const SuitAlterationsWrapperImg = styled.div`
  height: 120px;
  width: 120px;
  margin-right: 60px;
  ${(props) => props.bottomSuit && 'height: 163px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

export const SuitAlterationsImg = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

export const SuitAlterationsWrapperButton = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

export const SuitAlterationsButton = styled(FlexContainer)`
  cursor: pointer;
  height: 40px;
  line-height: 1;
  margin: 10px 0px;
  padding: 0 5px;
  text-align: center;
  width: 200px;
  ${(props) => props.largeButton && 'width: 350px;'}
  ${(props) => !props.unavailable && `
    border: 0.75px solid ${colors.blueGrey};
    border-radius: 3px;
    box-sizing: border-box;
    margin: 5px 0px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

export const Filter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 224px;
  padding: 0px 22px;
  color: ${colors.white};
  box-sizing: border-box;
  text-align: center;
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 12px;
    width: calc(50vw - 50px);
    ${({ isVisibleMobile }) => isVisibleMobile && `
      visibility: visible;
    `};
  }
`;

export const FilterTitle = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 5px;
  }
`;

export const FilterText = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 9px;
    line-height: 11px;
  }
`;

export const MaskContainer = styled.div`
  width: 100%;
  margin-top: -121px;
  margin-bottom: 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: -80px;
  }
`;

export const MaskTextContainer = styled.div`
  margin-top: 20px;
`;
