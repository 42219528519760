import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withAppContext from '../../withAppContext';
import {
  Label, Row, Subtitle, Content, colors, mobileThresholdPixels,
} from './orderStyledComponents';
import Dots from '../home/v3/Dots';
import tree from './tree';

const YellowCard = styled.button`
  margin: 0px 15px 0px 15px;
  height: 70px;
  width: 240px;
  background-color: ${colors.white};
  color: ${colors.navy};
  border-radius: 3px;
  ${({ active }) => active
    ? `
      box-shadow: 0px 1px 6px ${colors.blueGrey};
      border-width: 0px;
      border-left: 7px solid ${colors.yellow};
    `
    : `
      border: 0.75px solid ${colors.blueGrey};
      box-sizing: border-box;
    `}
  outline: 0;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: 42px;
    margin: 2.5px 0px 2.5px;
  }
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 60px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 7px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: ${({ large }) => large ? '1082' : '976'}px;
  margin-top: 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: 23px 0px 0px 0px;
  }
`;

const SubLabel = styled(Label)`
  font-weight: 400;
`;

const NewTypeOfWorkContainer = styled.div`
  position: absolute;
`;

const NewTypeOfWorkText = styled.p`
  position: relative;
  top: -50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: ${colors.yellow};
  margin-left: 18px;
`;

const Step1Tree = ({ context: { isMobile }, selectTypeOfWork, selectedTypeOfWork }) => (
  <Row mobileMarginTop={20}>
    <Content marginTop={7}>
      <Subtitle>Que peut-on faire pour vous ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <Row2>
        <CardsContainer large>
          {tree[0].typesOfWork.map((typeOfWork) => (
            <YellowCard
              key={typeOfWork.slug}
              slug={typeOfWork.slug}
              onClick={() => selectTypeOfWork(typeOfWork.slug)}
              active={selectedTypeOfWork === typeOfWork.slug}
            >
              {!isMobile && typeOfWork.slug === 'creation'
                && (
                  <NewTypeOfWorkContainer>
                    <NewTypeOfWorkText>Nouveau !</NewTypeOfWorkText>
                  </NewTypeOfWorkContainer>
                )}
              <Label>
                {typeOfWork.label}
              </Label>
              {!isMobile
                ? (
                  <SubLabel>
                    {typeOfWork.subLabel}
                  </SubLabel>
                )
                : ''}
            </YellowCard>
          ))}
        </CardsContainer>
      </Row2>
    </Content>
  </Row>
);

Step1Tree.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  selectTypeOfWork: PropTypes.func,
  selectedTypeOfWork: PropTypes.string,
};

Step1Tree.defaultProps = {
  selectTypeOfWork() { },
  selectedTypeOfWork: '',
};

export default withAppContext(Step1Tree);
